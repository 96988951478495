// import { Media } from "@sumit-platforms/types";
// import React from "react";
// import { faListMusic } from "@fortawesome/pro-light-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Popover from "@mui/material/Popover";
//
// import "./MediaSwitcher.scss";
//
// export const MediaSwitcher = ({
//   handleDownloadMedia,
//   onSelectMedia,
//   mediaSources,
//   selectedMedia,
// }: {
//   onSelectMedia: (media: Media) => void;
//   handleDownloadMedia: (e: any, idMedia: number) => Promise<void>;
//   mediaSources: Media[];
//   selectedMedia: Media;
// }) => {
//   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
//     null
//   );
//   const handleClick = (event: any) => {
//     console.log("click");
//     event.preventDefault()
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleClose = (event: any) => {
//     event.preventDefault()
//     setAnchorEl(null);
//   };
//
//   const open = Boolean(anchorEl);
//   const id = open ? "simple-popover" : undefined;
//
//   return (
//     <div
//       className={"MediaSwitcher"}
//       onClick={handleClick}
//       aria-describedby={id}
//     >
//       <span className={"mediaName"}>{selectedMedia.name}</span>
//       <FontAwesomeIcon icon={faListMusic} />
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         className={"list-sources-container"}
//         anchorOrigin={{
//           vertical: "top",
//           horizontal: "left",
//         }}
//       >
//         <div className={"list-sources-container"}>list</div>
//       </Popover>
//     </div>
//   );
// };

import { Media } from "@sumit-platforms/types";
import React, { useState } from "react";
import { faDownload, faListMusic } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "@mui/material/Popover";

import "./MediaSwitcher.scss";
import { Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { directionState, featureFlagsState } from "../../store/states";
import { Tooltip } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";

export const MediaSwitcher = ({
  handleDownloadMedia,
  onSelectMedia,
  mediaSources,
  selectedMedia,
}: {
  onSelectMedia: (media: Media) => void;
  handleDownloadMedia: (e: any, idMedia: number) => Promise<void>;
  mediaSources: Media[];
  selectedMedia: Media;
}) => {
  const { t } = useTranslation();
  const ff = useRecoilValue(featureFlagsState);
  const direction = useRecoilValue(directionState);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Tooltip title={t("select_media")} placement={"top"}>
      <div>
        <div
          className={classNames(`MediaSwitcher ${direction}`)}
          aria-describedby={id}
          onClick={handleClick}
        >
          <span className={classNames("fps", { missing: !selectedMedia.fps })}>
            ({selectedMedia.fps ? `${selectedMedia.fps}` : "No FPS"})
          </span>
          <span className={"mediaName"}>{selectedMedia.name}</span>
          <FontAwesomeIcon icon={faListMusic} />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className={"list-sources-container"}>
            {mediaSources.map((media, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    onSelectMedia(media);
                    setAnchorEl(null);
                  }}
                  className={classNames("sourceItem", {
                    selected: selectedMedia.idMedia === media.idMedia,
                  })}
                >
                  {media.name}
                  <span
                    className={classNames("fps", {
                      missing: !media.fps,
                    })}
                  >
                    ({media.fps ? `FPS - ${media.fps}` : "No FPS"})
                  </span>
                  {ff?.allowDownloadMedia && (
                    <Tooltip title={t("download_media")}>
                      <IconButton
                        onClick={async (e: any) => {
                          setIsLoading(true);
                          await handleDownloadMedia(e, media.idMedia);
                          setIsLoading(false);
                        }}
                        className={"downloadIcon"}
                        disabled={isLoading}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        </Popover>
      </div>
    </Tooltip>
  );
};
