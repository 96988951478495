import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { NewTimerPicker } from "../NewTimerPicker/NewTimerPicker";
import { CustomEditor, SubtitleRangeElement } from "../../types";
import EditorService from "../../services/EditorService";
import MediaService from "../../services/MediaService";
import { useRecoilValue } from "recoil";
import { directionState, isDisabledState } from "../../store/states";
import { useFocused, useSlateStatic } from "@sumit-platforms/slate-react";
import LoadingPlaceholder from "../Video/LoadingPlaceholder";
import RangeMediaVideo, { RangeMediaRef } from "../RangeMedia/RangeMediaVideo";

import "./RangeSubtitles.scss";
import "./RangeMedia.scss";

interface Props {
  attributes: any;
  children?: React.ReactNode;
  handleBlur?: (rangeIndex?: number) => void;
  startIndex?: number | null;
  renderIndex?: number | null;
  isPlaceholder?: boolean;
  isElementSelected?: boolean;
}

const RangeMedia = ({
  attributes,
  children,
  handleBlur,
  startIndex,
  renderIndex,
  isPlaceholder,
  isElementSelected,
}: Props) => {
  if (!_.isNumber(startIndex) || !_.isNumber(renderIndex)) {
    throw new Error("startIndex and renderIndex must be numbers");
  }
  const editor = useSlateStatic() as CustomEditor;
  const disabled = useRecoilValue(isDisabledState);
  const direction = useRecoilValue(directionState);

  const isEditorFocused = useFocused();

  const element = editor.children[
    (startIndex || 0) + (renderIndex || 0)
  ] as SubtitleRangeElement;

  const rangeRef = useRef<any>(null);
  const isFocusedPrevValue = useRef(false);
  const mediaVideoRef = useRef<RangeMediaRef>(null);

  const onBlur = useCallback(() => {
    const rangeIndex = EditorService.getRangeIndex({ editor, element });
    if (handleBlur) {
      handleBlur(rangeIndex);
    }
  }, [handleBlur, editor, element]);

  const handleTimePickerBlur = useCallback(
    (time: number, position: "start" | "end") => {
      EditorService.updateRangeTime({
        editor,
        time,
        position,
        rangeIndex: EditorService.getRangeIndex({ element, editor }),
        element,
      });
    },
    [editor, element]
  );

  const handleAddFrames = useCallback(
    (
      framesToAdd: number,
      position: "start" | "end",
      limit?: {
        min?: number;
        max?: number;
      }
    ) => {
      EditorService.handleAddFrames({
        editor,
        framesToAdd,
        position,
        rangeIndex: EditorService.getRangeIndex({ editor, element }),
        element,
        disabled,
        limit,
      });
    },
    [disabled, editor, element]
  );

  useEffect(() => {
    if (isFocusedPrevValue.current && !isElementSelected) {
      onBlur();
    }

    isFocusedPrevValue.current = !!isElementSelected;
  }, [isElementSelected]);

  const focusMediaCaption = (e: React.MouseEvent) => {
    e.preventDefault();
    if (mediaVideoRef?.current?.focusCaption) {
      mediaVideoRef.current.focusCaption();
    }
  };

  return (
    <div
      className={classNames(`SlateRangeSubtitle MediaRange`, direction, {
        error: !_.isEmpty(element.range.validation?.errors),
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
      onClick={focusMediaCaption}
      ref={rangeRef}
      {...attributes}
    >
      <div className="subtitleRangeTimes subtitles" contentEditable={false}>
        <NewTimerPicker
          className={classNames({
            overlapping:
              element.range.validation?.errors?.overlapping_prev ||
              element.range.validation?.errors?.start_after_end,
            outOfDuration:
              element.range.validation?.errors?.is_range_out_of_duration,
          })}
          isFocused={isElementSelected && isEditorFocused}
          value={element.range.st}
          deductTime={() => handleAddFrames(-1, "start")}
          addTime={() =>
            handleAddFrames(1, "start", { max: element.range.et - 0.01 })
          }
          disabled={isPlaceholder || disabled}
          isPlaceholderPicker={isPlaceholder}
          useCurrentTimeSetter={startIndex > 0}
          position={"st"}
          fps={MediaService.frameRate}
          tcOffsets={MediaService.tcOffsets}
          handleBlur={(time: number) => handleTimePickerBlur(time, "start")}
          maxLimit={(MediaService?.media?.duration || 0) + 3}
        />
        <NewTimerPicker
          className={classNames({
            overlapping:
              element.range.validation?.errors?.overlapping_next ||
              element.range.validation?.errors?.start_after_end,
            outOfDuration:
              element.range.validation?.errors?.is_range_out_of_duration,
          })}
          isFocused={isElementSelected && isEditorFocused}
          value={element.range.et}
          deductTime={() =>
            handleAddFrames(-1, "end", { min: element.range.st + 0.01 })
          }
          addTime={() =>
            handleAddFrames(1, "end", {
              max: (MediaService?.media?.duration || 0) + 3,
            })
          }
          disabled={isPlaceholder || disabled}
          isPlaceholderPicker={isPlaceholder}
          useCurrentTimeSetter={startIndex > 0}
          position={"et"}
          fps={MediaService.frameRate}
          tcOffsets={MediaService.tcOffsets}
          handleBlur={(time: number) => handleTimePickerBlur(time, "end")}
          maxLimit={(MediaService?.media?.duration || 0) + 3}
          minLimit={element.range.st + MediaService.frameLength} // Minimum 1 frame above st
        />
      </div>
      <div className={"rangeMediaContent"}>
        <LoadingPlaceholder />
        <RangeMediaVideo
          element={element}
          index={startIndex + renderIndex + 1}
          isElementSelected={isElementSelected}
          children={children}
          isPlaceholder={isPlaceholder}
        />
      </div>
    </div>
  );
};

export default RangeMedia;
