import React from "react";
import OtpInput from "react-otp-input";

import "./OtpInput.scss";

export const OTPInput = ({
  onChange,
  value,
}: {
  value: string;
  onChange: (value: string) => void;
}) => (
  <OtpInput
    shouldAutoFocus
    value={value}
    onChange={onChange}
    numInputs={6}
    renderInput={(props) => <input {...props} className="digit" />}
  />
);
