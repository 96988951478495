type SummarizePayload = {
  input_blob_path: string;
  output_blob_path: string;
  input_bucket: string;
  language?: string;
  style?: string;
  content_type: "dialogue" | "informational" | "auto";
  summary_length: "brief" | "detailed";
  summary_type: "bullets" | "paragraphs";
  participant_type: "none" | "basic" | "detailed";
  conclusion_type: "none" | "basic" | "detailed";
  stt_guideline: boolean; // will get true if machine only
  callback?: string;
  callback_payload?: any;
};

type SummaryBulletContent = {
  header: string;
  bullets: string[];
};

type SummaryParagraphContent = {
  header: string;
  paragraph: string;
};

export type SummaryParticipantsContent = {
  name: string;
  details?: string;
};

type SummaryContent = SummaryBulletContent | SummaryParagraphContent;

type Summary = {
  title: string;
  content: SummaryContent[];
  participants?: SummaryParticipantsContent[];
  conclusions?: string[];
};

enum BriefLength {
  SHORT = "short",
  MEDIUM = "medium", // currently not in use
  LONG = "long",
}

enum BriefFormat {
  BULLETS = "bullets",
  PARAGRAPHS = "paragraphs",
}

type BriefValues = BriefLength | BriefFormat;

type ValueOfSummarizePayload = SummarizePayload[keyof SummarizePayload];

const algoBriefValuesMap: Record<BriefValues, ValueOfSummarizePayload> = {
  short: "short",
  medium: "brief", // currently not in use
  long: "detailed",
  bullets: "bullets",
  paragraphs: "paragraphs",
};

type SummarizeDefaultValueKeys =
  | "summary_length"
  | "summary_type"
  | "content_type"
  | "participant_type"
  | "conclusion_type"
  | "stt_guideline";

const summarizeDefaultValues: Pick<
  SummarizePayload,
  SummarizeDefaultValueKeys
> = {
  content_type: "informational",
  summary_length: "brief",
  summary_type: "bullets",
  participant_type: "detailed",
  conclusion_type: "detailed",
  stt_guideline: false,
};

type BriefExportOptions = {
  fileName: string;
  briefLength?: BriefLength;
  briefFormat?: BriefFormat;
};

export type {
  SummarizePayload,
  Summary,
  BriefExportOptions,
  SummarizeDefaultValueKeys,
  BriefValues,
  ValueOfSummarizePayload,
  SummaryBulletContent,
  SummaryParagraphContent,
};
export { BriefLength, BriefFormat, algoBriefValuesMap, summarizeDefaultValues };

//! Exmaple
//payload = {
//
//     "input_blob_path": "",
//
//     "output_blob_path": "",
//
//     "language": "he-IL",               // Language in ICU format
//
//     "style": "default",                // Style of summarization (currently, "default" is the only supported option)
//
//?     "content_type": "auto",        // options: "dialogue", "informational", "auto". Defaults to "auto". // this is not rendered in the ui but we will send "informational"
//
//!     "summary_length": "brief",      // options: "brief", "detailed". Defaults to "brief". // this is "brief_length"
//
//!     "summary_type": "bullets"          // options: "bullets", "paragraphs". Defaults to "bullets". // This is "brief_format"
//
// }
