import React, { useCallback, useMemo } from "react";
import { BriefLength } from "@sumit-platforms/types";
import { ButtonGroupOption, ToggleButtonGroup } from "../../../index";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface Props {
  defaultValue?: BriefLength;
  value: BriefLength;
  onChange: (value: BriefLength) => void;
  isLoading?: boolean;
}

const BriefLengthGroup = ({
  value,
  defaultValue,
  onChange,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const briefButtons: ButtonGroupOption[] = useMemo(() => {
    const buttons = [
      {
        label: _.capitalize(t(BriefLength.SHORT)),
        value: BriefLength.SHORT,
      },
      {
        label: _.capitalize(t(BriefLength.LONG)),
        value: BriefLength.LONG,
      },
    ];
    return buttons;
  }, []);

  const handleBriefLengthChange = useCallback(
    (event: any, value: BriefLength) => {
      onChange(value);
    },
    []
  );

  return (
    <ToggleButtonGroup
      buttons={briefButtons}
      exclusive={true}
      onChange={handleBriefLengthChange}
      value={value}
      disabled={isLoading}
      defaultValue={defaultValue}
      label={t("brief_length")}
    />
  );
};

export default BriefLengthGroup;
