import { Job } from "./Job";

type JobSplit = {
  idJobSplit: number;
  idJob: number;
  idSourceJob: number;
  splitIndex: number;
  startTime: number;
  endTime: number;
  duration: number;
  job?: Job;
  sourceJob?: Job;
  deletedAt?: Date | null;
};
enum JobSplitType {
  SOURCE = "SOURCE",
  SPLIT = "SPLIT",
}

type JobSplitRequestParams = {
  idJobs: number[];
  splitCount: number;
};

type JobUnSplitRequestParams = {
  idJobs: number[];
};

export { JobSplitType };

export type { JobSplitRequestParams, JobSplit, JobUnSplitRequestParams };
