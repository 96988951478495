import React, { useState } from "react";
import { Modal } from "../../core/Modal/Modal";
import { Grid, Typography } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { useTranslation } from "react-i18next";
import Input from "../../core/Input/Input";

interface EventRecordModalProps {
  onApprove: (meetingUrl: string) => Promise<void>;
  onCancel: () => void;
}

export const EventRecordModal = ({
  onApprove,
  onCancel,
}: EventRecordModalProps) => {
  const { t } = useTranslation();
  const [meetingUrl, setMeetingUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleApprove = async () => {
    try {
      if (meetingUrl) {
        setIsLoading(true);
        await onApprove(meetingUrl);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Modal type={"info"} closeModal={onCancel}>
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        py={3}
        px={3}
        width={"35rem"}
        textAlign={"center"}
      >
        <Grid item pt={1}>
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("new_event_record_modal_title")}
          </Typography>
        </Grid>

        <Grid item pt={2}>
          <Typography sx={{ fontSize: 16 }} pb={1}>
            {t("new_event_record_modal_description")}
          </Typography>
        </Grid>

        <Grid item pt={2}>
          <Input
            type="text"
            value={meetingUrl}
            onChange={(e) => setMeetingUrl(e.target?.value || "")}
          />
        </Grid>

        <Grid item display="flex" justifyContent={"center"} gap={3} py={2}>
          <Button onClick={onCancel} variant="outlined">
            {t("cancel")}
          </Button>

          <Button
            onClick={handleApprove}
            color="primary"
            disabled={!meetingUrl || isLoading}
            loading={isLoading}
          >
            Approve
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
