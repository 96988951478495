import _ from "lodash";
import {
  faMobileScreen,
  faShieldHalved,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { bazarTheme } from "../../bazar-theme";
import React, { useCallback, useEffect, useState } from "react";
import { MFAMethods } from "@sumit-platforms/types";
import { MFAHeader } from "./MfaHeader";

import "../../../style/auth.scss";

interface Props {
  enrollSMS: () => Promise<void>;
  validateMfaSession: () => Promise<any>;
  enrollTOTP: () => Promise<void>;
  logOut: () => Promise<void>;
}

export const MFAPage = ({
  enrollSMS,
  enrollTOTP,
  validateMfaSession,
  logOut,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState("");
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);

  const handleLogout = useCallback(async () => {
    setIsSignoutLoading(true);
    try {
      await logOut();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSignoutLoading(false);
    }
  }, []);

  useEffect(() => {
    validateMfaSession();
  }, []);

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="MFAPage AuthPage MFA">
        <div className="authContainer">
          <MFAHeader handleLogout={handleLogout} />
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h5"
              component="h1"
              align="center"
              color="text.primary"
            >
              {t("mfa_setup_title")}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              color="text.secondary"
            >
              {t("mfa_choose")}
            </Typography>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              startIcon={
                loading === MFAMethods.sms ? (
                  <CircularProgress size={20} />
                ) : (
                  <FontAwesomeIcon icon={faMobileScreen} />
                )
              }
              onClick={async () => {
                setLoading(MFAMethods.sms);
                await enrollSMS();
                setLoading("");
              }}
              disabled={!_.isEmpty(loading) || isSignoutLoading}
              sx={{ justifyContent: "flex-start", py: 2 }}
            >
              <Box sx={{ textAlign: "left", ml: 2 }}>
                <Typography variant="subtitle1">SMS Authentication</Typography>
                <Typography variant="body2" color="text.secondary">
                  Receive verification codes via text message
                </Typography>
              </Box>
            </Button>

            <Button
              variant="outlined"
              size="large"
              startIcon={
                loading === MFAMethods.totp ? (
                  <CircularProgress size={20} />
                ) : (
                  <FontAwesomeIcon icon={faShieldHalved} />
                )
              }
              onClick={async () => {
                setLoading(MFAMethods.totp);
                await enrollTOTP();
                setLoading("");
              }}
              disabled={!_.isEmpty(loading) || isSignoutLoading}
              sx={{ justifyContent: "flex-start", py: 2 }}
            >
              <Box sx={{ textAlign: "left", ml: 2 }}>
                <Typography variant="subtitle1">Authenticator App</Typography>
                <Typography variant="body2" color="text.secondary">
                  Use an authenticator app like Google Authenticator or Authy
                </Typography>
              </Box>
            </Button>
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
};
