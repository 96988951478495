import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { Button as LoadingButton } from "../../core/Button/Button";
import OtpInput from "react-otp-input";
import { Trans, useTranslation } from "react-i18next";
import { bazarTheme } from "../../bazar-theme";
import { MFAMethodOptions, MFAMethods } from "@sumit-platforms/types";
import { useLocation } from "react-router-dom";
import { MFAHeader } from "../../pages/MFA/MfaHeader";

import "../../../style/auth.scss";
import "../../pages/MFA/mfa.scss";

interface props {
  verifyOTPCode: (code: string) => Promise<void>;
  validateMfaSession: () => Promise<{
    email: string | null;
    phone: string | null;
  } | null>;
  logOut?: () => Promise<void>;
  showHeader?: boolean;
}

export const VerifyOTPCode: React.FC<props> = ({
  verifyOTPCode,
  validateMfaSession,
  logOut,
  showHeader = true,
}: props) => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);
  const [maskedMail, setMaskedMail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const _validateMfaSession = useCallback(async () => {
    const response = await validateMfaSession();
    if (response?.email) {
      setMaskedMail(response.email);
    }
  }, []);

  useEffect(() => {
    _validateMfaSession();
  }, []);

  const handleVerifyCode = async (value: string) => {
    setIsLoading(true);
    const code = value;
    if (code.length !== 6) {
      setIsLoading(false);
      return;
    }
    setError("");
    await verifyOTPCode(code)
      .catch((error) => {
        console.error(error);
        setError(
          `${t("otp_verify_error")}\n${t(error?.response?.data?.error) || ""}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOtpInputChange = useCallback(
    (value: string) => {
      setOtp(value);
      if (value.length === 6) {
        handleVerifyCode(value);
      }
    },
    [handleVerifyCode]
  );

  const handleLogout = useCallback(async () => {
    setIsSignoutLoading(true);
    try {
      if (logOut) await logOut();
    } catch (e) {
      console.log();
    } finally {
      setIsSignoutLoading(false);
    }
  }, []);

  return (
    <>
      {showHeader && <MFAHeader handleLogout={handleLogout} />}
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          align="center"
          color="text.primary"
        >
          {t("mfa_verify_otp_title")}
        </Typography>
      </Box>
      {state?.qrCode && (
        <Box sx={{ mb: 3 }} className="qrBox">
          <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary">
            {t("mfa_otp_qr_instructions")}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={state.qrCode} className="qrCode" />
          </Box>
        </Box>
      )}

      <Box>
        <Typography variant="body1" gutterBottom color="text.secondary">
          <Trans
            i18nKey={"mfa_verify_otp_instructions"}
            components={{ b: <strong style={{ fontSize: "15px" }} /> }}
          />
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "center", margin: 2 }}
          className="codeVerification"
        >
          <OtpInput
            shouldAutoFocus={true}
            value={otp}
            onChange={handleOtpInputChange}
            numInputs={6}
            renderInput={(props) => <input {...props} className="digit" />}
          />
        </Box>
        {maskedMail && !state?.qrCode && (
          <Typography variant="body2" align="center" color="text.secondary">
            {t("mfa_send_to_app", { mail: maskedMail })}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 1,
            minHeight: 30,
          }}
          className="codeError"
        >
          <Typography variant="body1" gutterBottom align="center" color="error">
            {t(error || "")}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => handleVerifyCode(otp)}
            loading={isLoading}
            disabled={otp.length < 6 || isSignoutLoading}
          >
            {t("mfa_verify_code_button")}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
