import { Client } from "@sumit-platforms/types";
import { create } from "zustand";

export type ClientStore = {
  client: Client | null;
  setClient: (client: Client | null) => void;
};

export const clientStore = create<ClientStore>((set) => ({
  client: null,
  setClient: (client: Client | null) => {
    set((state: ClientStore) => {
      return { ...state, client };
    });
  },
}));
