import React, { useCallback } from "react";
import { Button, FormInput } from "../../../index";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReAuthHeader } from "../../modals/ReAuthModal/ReAuthHeader";
import { ForgotPasswordButton } from "./ForgotPasswordButton";

type LoginEmailInputs = {
  email: string;
  password: string;
};

interface SignInWithEmailAndPasswordFormProps {
  onSubmit: any;
  hint?: {
    email?: { value?: string | undefined; disabled?: boolean | undefined };
  };
  resetPassword?: () => void;
  loading: boolean;
}

export const SignInWithEmailAndPasswordForm = ({
  onSubmit,
  resetPassword,
  hint,
  loading,
}: SignInWithEmailAndPasswordFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<LoginEmailInputs>({
    mode: "onChange",
    defaultValues: { email: hint?.email?.value },
  });

  return (
    <>
      <ReAuthHeader />
      <form className="styledForm" onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          className="styledInput alignEnd"
          placeholder={t("email")}
          autofocus={true}
          disabled={(hint?.email?.value && hint?.email?.disabled) || loading}
          error={isDirty && !isValid ? errors.email?.message : ""}
          register={register("email", {
            required: { value: true, message: t("required_field") },
          })}
        />
        <FormInput
          className="styledInput alignEnd"
          placeholder={t("password")}
          type="password"
          disabled={loading}
          error={isDirty && !isValid ? errors.password?.message : ""}
          register={register("password", {
            required: { value: true, message: t("required_field") },
          })}
          floatActionElement={
            resetPassword && <ForgotPasswordButton onClick={resetPassword} />
          }
        />
        <div className={"buttonsWrapper"}>
          <Button
            className={"signInButton"}
            loading={loading}
            disabled={!isValid}
            type="submit"
            onClick={() => handleSubmit(onSubmit)}
          >
            {t("sign_in")}
          </Button>
        </div>
      </form>
    </>
  );
};
