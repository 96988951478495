import { useCallback, useEffect, useState } from "react";
import {
  AuthenticateWithEmailAndPasswordResponse,
  User,
} from "@sumit-platforms/types";
import { useUser } from "../store/user";
import * as Sentry from "@sentry/react";
import axios from "axios";

export const useAuth = ({
  config,
  getUserDetails,
}: {
  config: any;
  getUserDetails?: () => Promise<User>;
}): {
  user: User | null;
  setUser: (user: User | null) => void;
  roles: any;
  isLoading: boolean;
  signIn: typeof signIn;
  signInWithEmailAndPassword: typeof signInWithEmailAndPassword;
  resetPassword: typeof resetPassword;
  signOut: typeof signOut;
  handleUserAuthentication: typeof handleUserAuthentication;
} => {
  const authUrl = `${config.server.host}/${config.server.auth}`;

  const { user, roles, setUser } = useUser();

  const headers = {
    "Cache-Control": "no-cache",
  };

  const [loading, setLoading] = useState(!user);
  useEffect(() => {
    setLoading(!!user);
    handleUserAuthentication();
  }, [user]);

  const handleUserAuthentication = useCallback(async () => {
    try {
      if (user || !getUserDetails) return;
      setLoading(true);
      let loggedInUser = null;

      const userDetails: any = await getUserDetails();
      if (userDetails) {
        loggedInUser = {
          ...userDetails,
        };
      }
      setUser(loggedInUser as User);
      Sentry.setUser({
        uid: userDetails.idUser,
        name: `${userDetails.firstName} ${userDetails.lastName}`,
        email: userDetails.email || "",
      });
    } catch (err) {
      console.error(err);
      // setUser(null);
      // LocalStorageService.clearAll();
      // signOut();
    } finally {
      setLoading(false);
    }
  }, [getUserDetails, setUser, user]);

  const signIn = async (
    callbackPath: string | null,
    options?: { newTab?: boolean }
  ) => {
    return axios
      .get(`${authUrl}/login`, {
        headers: headers,
        params: {
          callbackPath: callbackPath || window.location.href,
        },
      })
      .then((res) => {
        const redirectionUrl = res.data.redirectUrl;
        if (redirectionUrl) {
          if (options?.newTab) {
            window.open(redirectionUrl, "_blank");
          } else {
            window.location.href = redirectionUrl;
          }
        }
      });
  };

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<AuthenticateWithEmailAndPasswordResponse> => {
    try {
      const result = await axios.post<{ success: boolean }>(
        `${authUrl}/login-with-email-and-password`,
        {
          email,
          password,
        },
        {
          headers: headers,
        }
      );
      return result?.data;
    } catch (e: any) {
      console.error(`Failed to login with email and password.`, e);
      return {
        success: false,
        message: e?.response?.data?.message || e?.message || "",
      };
    }
  };
  const resetPassword = async (
    email: string
  ): Promise<{ success: boolean; message?: string }> => {
    try {
      const result = await axios.post<{ success: boolean }>(
        `${authUrl}/reset-password`,
        {
          email,
        },
        {
          headers: headers,
        }
      );
      return result?.data;
    } catch (e: any) {
      console.error(`Failed to login with email and password.`, e);
      return {
        success: false,
        message: e?.response?.data?.message || e?.message || "",
      };
    }
  };

  const signOut = async () => {
    return axios
      .get(`${authUrl}/logout?callbackPath=${window.location}`, {
        withCredentials: true,
        headers,
      })
      .then((res) => {
        const redirectionUrl = res.data.redirectUrl;
        if (redirectionUrl) {
          window.location.href = redirectionUrl;
        }
      });
  };

  return {
    user,
    setUser,
    roles,
    signIn,
    signInWithEmailAndPassword,
    signOut,
    handleUserAuthentication,
    isLoading: loading,
    resetPassword,
  };
};
