import { Typography } from "@mui/material";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

interface Props {
  onClick: any;
}

export const ForgotPasswordButton = ({ onClick }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (e) {
      console.log("e :", e);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Typography
      variant="body1"
      color="text.secondary"
      sx={{ mt: 4, position: "absolute" }}
      className={"ForgotPasswordButton"}
    >
      <Button
        color="primary"
        onClick={handleClick}
        sx={{ textTransform: "none", padding: "4px 10px", marginTop: "4px" }}
        disabled={loading}
      >
        {t("forgot_password_button")}
      </Button>
    </Typography>
  );
};
