export enum MediaProvider {
  googleDrive = "GOOGLE_DRIVE",
  fileInput = "FILE_INPUT",
  dropbox = "DROPBOX",
  vimeo = "VIMEO",
  youtube = "YOUTUBE",
  sheet = "SHEET",
  merge = "MERGE",
  split = "SPLIT",
  kit = "KIT",
  downsample = "DOWNSAMPLE",
  premierePlugin = "PREMIERE_PLUGIN",
  recallAI = "RECALL_AI",
  transcribeTask = "TRANSCRIBE_TASK",
  mustRecorder = "MUST_RECORDER",
}
