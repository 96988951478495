import React from "react";
import LogoutButton from "./LogoutButton";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";

interface Props {
  handleLogout?: () => Promise<void>;
}

export const MFAHeader = ({ handleLogout }: Props) => {
  return (
    <div className="headerTitle">
      {handleLogout && <LogoutButton handleLogout={handleLogout} />}
      <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
    </div>
  );
};
