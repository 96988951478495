import * as _ from "lodash";
import { JobTypes } from "../entities/JobTypes";
import { Align, FontSettings, TextDecoration } from "./CommonConfig";

export type ValidationJobTypes = Pick<JobTypes, "subtitles">;

export enum ReadSpeed {
  CPS = "CPS",
  WPM = "WPM",
}
export class ValidationLanguagePage {
  limitSubtitlesLine = 0;
  limitLineLength = 0;
  font: FontSettings = {
    fontFamily: "Arial",
    fontSize: 14,
    align: Align.justify,
    color: "#fff",
    backgroundColor: "#fff",
    textDecoration: [TextDecoration.underline],
  };

  constructor(args?: Partial<ValidationGeneralPage>) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export class ValidationGeneralPage extends ValidationLanguagePage {
  presetName = "";
  subtitlesLength: {
    min: number;
    max: number;
  } = {
    min: 0,
    max: 0,
  };
  readSpeed: {
    readSpeed: ReadSpeed;
    charsPerSecond: number;
    wordsPerMinute: number;
  } = {
    readSpeed: ReadSpeed.CPS,
    charsPerSecond: 0,
    wordsPerMinute: 0,
  };
  framesBetweenSubtitles = 0;
  framesBetweenSubtitlesToCutline = 0;

  constructor(args?: Partial<ValidationGeneralPage>) {
    super(args);
    if (args && typeof args !== "undefined") {
      Object.assign(this, _.defaultsDeep(args, this));
    }
  }
}

export type GeneralLang = "general_lang";

export enum ValidationLang {
  he_IL = "he_IL",
  en_US = "en_US",
  ar_IL = "ar_IL",
  es_ES = "es_ES",
  ru_RU = "ru_RU",
  pt_BR = "pt_BR",
  fr_FR = "fr_FR",
  hi_IN = "hi_IN",
  de_DE = "de_DE",
  zh_CN = "zh_CN",
  cs_CZ = "cs_CZ",
  nl_NL = "nl_NL",
  fi_FI = "fi_FI",
  id_ID = "id_ID",
  it_IT = "it_IT",
  ja_JP = "ja_JP",
  ko_KR = "ko_KR",
  ms_MY = "ms_MY",
  no_NO = "no_NO",
  pl_PL = "pl_PL",
  pt_PT = "pt_PT",
  es_419 = "es_419",
  sv_SE = "sv_SE",
  tr_TR = "tr_TR",
  ro_RO = "ro_RO",
  am_ET = "am_ET",
  uk_UA = "uk_UA",
}
export type ValidationsConfig = Record<GeneralLang, ValidationGeneralPage> &
  Partial<Record<ValidationLang, ValidationLanguagePage>>;
