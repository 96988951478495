import config from "../config";
import axios from "axios";

const clientOverviewEndpoint = `${config.server.host}/${config.server.clientOverview}`;

interface IGetOverViewArgs {
  idClient: number;
}

export const getOverview = (data: IGetOverViewArgs) =>
  axios
    .get(`${clientOverviewEndpoint}/${data.idClient}`)
    .then((res) => res.data);
