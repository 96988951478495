import React, { useCallback } from "react";
import { SignInForm } from "../../components/SignInForm/SignInForm";
import { ReAuthNote } from "./ReAuthNote";
import { AuthenticateWithEmailAndPasswordResponse } from "@sumit-platforms/types";

import "./ReAuthModal.scss";

interface Props {
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<AuthenticateWithEmailAndPasswordResponse>;
  resetPassword: (
    email: string
  ) => Promise<{ success: boolean; message?: string }>;
  userEmail?: string;
  config: any;
  onSuccess: () => void;
  onFail: () => void;
  onResetPasswordSuccess: () => void;
}

export const ReAuthModal = ({
  signInWithEmailAndPassword,
  resetPassword,
  userEmail,
  config,
  onSuccess,
  onFail,
  onResetPasswordSuccess,
}: Props) => {
  return (
    <div className={"ReAuthModal"}>
      <SignInForm
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        resetPassword={resetPassword}
        hint={{ email: { value: userEmail, disabled: true } }}
        config={config}
        onSuccess={onSuccess}
        onFail={onFail}
        onResetPasswordSuccess={onResetPasswordSuccess}
      />
      <div className={"divider"}></div>

      <ReAuthNote />
    </div>
  );
};
