import React from "react";
import { useKeyboardShortcuts } from "../../hooks/useKeyboardShortcuts";
import { ShortcutAction } from "@sumit-platforms/types";

import "./Modal.scss";

export type ModalType = "warning" | "danger" | "info";

export interface ModalProps {
  closeModal: () => void;
  children: React.ReactNode;
  type: ModalType;
  className?: string;
}

export const Modal = ({
  closeModal,
  className,
  children,
  type = "info",
}: ModalProps) => {
  useKeyboardShortcuts({
    handlers: {
      [ShortcutAction.CLOSE_MODAL]: closeModal,
    },
    disabled: false,
  });
  return (
    <div className={`BazarModal ${className || ""}`} onClick={closeModal}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`innerModal ${type}`}
      >
        {children}
      </div>
    </div>
  );
};
