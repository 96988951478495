import _ from "lodash";
import config from "../../../../config";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CardContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/pro-solid-svg-icons";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  startOfDay,
  addWeeks,
  endOfDay,
} from "date-fns";
import Cookies from "js-cookie";
import enUS from "date-fns/locale/en-US";
import {
  Button,
  EventRecordModal,
  GoogleSignInButton,
  SpinningLoader,
} from "@sumit-platforms/ui-bazar";
import { useAuth, useToast } from "@sumit-platforms/ui-bazar/hooks";
import { useModal } from "@sumit-platforms/ui-bazar/store";

import { clientStore } from "../../../../store/client";
import { useGlobalData } from "../../../../store";
import * as recallService from "../../../../services/recallService";

import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

import MeetingScheduleEvent from "./MeetingScheduleEvent";
import {
  GoogleAuthService,
  LocalStorageService,
} from "@sumit-platforms/ui-bazar/services";

import "./MeetingSchedule.scss";

export type RecallEvent = {
  id: string;
  idBot: string;
  title: string;
  hasBot: boolean;
  start: Date;
  end: Date;
  meetingUrl: string;
  status: string | null;
  idJob?: number;
};

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const MeetingSchedule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { client } = clientStore();
  const { user } = useAuth({ config });
  const { setToast } = useGlobalData();
  const { toastSuccess, toastError } = useToast({ setToast });
  const [isLoading, setIsLoading] = useState(false);

  const googleAuthService = GoogleAuthService({ config });

  const { setModalContent, clearModalContent, setModalType } = useModal();
  const [calendarUser, setCalendarUser] = useState<any>(
    LocalStorageService.getItem("google-user") || null
  );
  const [loadingEventIds, setLoadingEventIds] = useState<Set<string>>(
    new Set()
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));
  const [endDate, setEndDate] = useState<Date>(
    endOfDay(addWeeks(new Date(), 1))
  );

  const { data, refetch } = useQuery({
    queryKey: ["calendar-events", startDate, endDate],
    queryFn: async () => {
      setIsLoading(true);
      const events = await recallService.getEvents({
        start: startDate,
        end: endDate,
      });
      setIsLoading(false);
      return events;
    },
    enabled: !!calendarUser,
  });

  const recordEvent = useMutation({
    mutationKey: ["check-limit"],
    mutationFn: recallService.recordEvent,
    onSuccess: () => {
      refetch();
    },
  });

  const cancelEventRecording = useMutation({
    mutationKey: ["check-limit"],
    mutationFn: recallService.cancelEventRecord,
    onSuccess: () => {
      refetch();
    },
  });

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      try {
        const authCode = tokenResponse.code;
        const user = await googleAuthService.authenticateWithCode(authCode);
        LocalStorageService.setItem("google-user", user);
        setCalendarUser(user);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
    scope: config.googleAuthProvider.scopes.calendar.join(" "),
  });

  const handleGoogleLogout = () => {
    setCalendarUser(null);
    Cookies.remove("google-auth");
    LocalStorageService.removeItem("google-user");
  };

  const handleGoToJob = (event: RecallEvent) => {
    if (!event.idJob) return;
    navigate(`/job/${event.idJob}`);
  };

  const events = useMemo(
    () =>
      data?.map((event: any) => ({
        ...event,
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
        title: event.summary,
      })) || [],
    [data]
  );

  const handleCalendarDateChange = (
    newSelectedDates: Date[] | { start: Date; end: Date }
  ) => {
    let startDate = new Date();
    let endDate = new Date();
    if (_.isArray(newSelectedDates)) {
      startDate = newSelectedDates[0];
      endDate = _.last(newSelectedDates) as Date;
    } else {
      startDate = newSelectedDates.start;
      endDate = newSelectedDates.end;
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleOpenNewMeetingModal = () => {
    setModalContent(
      <EventRecordModal
        onApprove={handleCreateNewEventRecord}
        onCancel={clearModalContent}
      />
    );
  };

  const handleCreateNewEventRecord = async (meetingUrl: string) => {
    if (!user || !client) return;
    try {
      await recordEvent.mutateAsync({
        idClient: client.idClient,
        idUser: user.idUser,
        meetingUrl,
      });
      clearModalContent();
      toastSuccess(t("create_meeting_record_success"));
    } catch (err) {
      toastError(t("create_meeting_record_fail"));
    }
  };

  const toggleEventRecord = async (event: RecallEvent) => {
    if (!user || !client) return;
    try {
      setLoadingEventIds((prev) => new Set(prev).add(event.id));
      if (event.hasBot) {
        await cancelEventRecording.mutateAsync(event.id, {
          onSuccess: async () => {
            await refetch();
            setLoadingEventIds((prev) => {
              const newSet = new Set(prev);
              newSet.delete(event.id);
              return newSet;
            });
          },
        });
      } else {
        await recordEvent.mutateAsync(
          {
            idClient: client.idClient,
            idUser: user.idUser,
            meetingUrl: event.meetingUrl,
            idEvent: event.id,
            startDate: event.start,
            title: event.title,
          },
          {
            onSuccess: async () => {
              await refetch();
              setLoadingEventIds((prev) => {
                const newSet = new Set(prev);
                newSet.delete(event.id);
                return newSet;
              });
            },
          }
        );
      }
    } catch (error) {
      toastError(t("create_meeting_record_fail"));
      setLoadingEventIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(event.id);
        return newSet;
      });
    }
  };

  return (
    <div className="meetingSchedule" ref={containerRef}>
      <div className="meetingScheduleHeader">
        {calendarUser && (
          <GoogleSignInButton
            signIn={googleLogin}
            signOut={handleGoogleLogout}
            user={calendarUser}
          />
        )}
        <Button
          className="newEvent roundBtn"
          onClick={handleOpenNewMeetingModal}
          disabled={!calendarUser}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      {!calendarUser && (
        <div className="loginContainer">
          <div className="authProviders">
            <GoogleSignInButton
              signIn={googleLogin}
              signOut={handleGoogleLogout}
              user={calendarUser}
            />
          </div>
          <Button className="newEvent" onClick={handleOpenNewMeetingModal}>
            Paste live meeting link
          </Button>
        </div>
      )}

      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{
          height: Number(containerRef.current?.offsetHeight) - 100,
          opacity: !calendarUser || isLoading ? 0.4 : 1,
          pointerEvents: calendarUser ? "auto" : "none",
        }}
        formats={{ eventTimeRangeFormat: () => "" }}
        scrollToTime={new Date()}
        defaultView="week"
        views={["day", "week", "month"]}
        onRangeChange={handleCalendarDateChange}
        components={{
          event: ({ title, event }: any) => (
            <MeetingScheduleEvent
              event={event}
              toggleEventRecord={toggleEventRecord}
              isLoading={loadingEventIds.has(event.id)}
            />
          ),
        }}
      />
      {isLoading && (
        <div className="loading">
          <SpinningLoader style={{ width: 48 }} />
        </div>
      )}
    </div>
  );
};

export default MeetingSchedule;
