import _ from "lodash";
import React, { useCallback } from "react";
import {
  InputLabel,
  Stack,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as ToggleButtonGroupMuiProps,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { bazarTheme } from "../../bazar-theme";
import { InfoTooltipIcon } from "../InfoTooltipIcon/InfoTooltipIcon";

import "./ToggleButtonGroup.scss";

export interface ButtonGroupOption {
  value: any;
  label?: string | IconDefinition;
  disabled?: boolean;
  infoTooltip?: string;
  hide?: boolean;
  icon?: IconDefinition;
}
export interface ToggleButtonGroupProps extends ToggleButtonGroupMuiProps {
  buttons: ButtonGroupOption[];
  exclusive?: boolean;
  setValue?: (value: any) => void;
  label?: string;
  labelId?: string;
  spacing?: number;
}

export const ToggleButtonGroup = ({
  buttons,
  setValue,
  exclusive = true,
  label,
  labelId,
  spacing,
  ...props
}: ToggleButtonGroupProps) => {
  const control: Partial<ToggleButtonGroupProps> = {
    value:
      !exclusive && !_.isArray(props.value)
        ? []
        : _.isNil(props.value)
        ? props.defaultValue
        : props.value,
    onChange: props.onChange,
    disabled: props.disabled,
  };

  if (exclusive) {
    control["exclusive"] = true;
  }

  const renderChildren = useCallback(
    (button: ButtonGroupOption, children: any) => {
      if (button.infoTooltip) {
        return (
          <InfoTooltipIcon tooltipText={button.infoTooltip}>
            {children}
          </InfoTooltipIcon>
        );
      } else return children;
    },
    []
  );

  return (
    <ThemeProvider theme={bazarTheme}>
      <Stack
        spacing={spacing || 0}
        alignItems="stretch"
        className="BazarToggleButtonGroup"
      >
        {label && (
          <InputLabel shrink htmlFor={labelId}>
            {label}
          </InputLabel>
        )}
        <MuiToggleButtonGroup {...control}>
          {buttons.map((b) =>
            b.hide ? null : (
              <ToggleButton
                className={"buttonGroup"}
                value={b.value}
                key={b.value}
                disabled={b.disabled}
              >
                {typeof b.label === "string"
                  ? renderChildren(
                      b,
                      <>
                        {b.icon && (
                          <FontAwesomeIcon
                            icon={b.icon}
                            style={{ marginRight: "6px" }}
                          />
                        )}
                        <span className={"buttonOption"}>{b.label}</span>
                      </>
                    )
                  : renderChildren(
                      b,
                      <FontAwesomeIcon icon={b.label as IconDefinition} />
                    )}
              </ToggleButton>
            )
          )}
        </MuiToggleButtonGroup>
      </Stack>
    </ThemeProvider>
  );
};
