import { useFocused, useSlateStatic } from "@sumit-platforms/slate-react";
import { forwardRef, useCallback, useImperativeHandle } from "react";
import classNames from "classnames";
import EditorService from "../../services/EditorService";
import _ from "lodash";
import { useRecoilValue } from "recoil";
import {
  currentTimeState,
  directionState,
  isDisabledState,
} from "../../store/states";
import { CustomEditor, SubtitleRangeElement } from "../../types";
import { RangeCount } from "../Ranges/RangeCount";
import RangeInfo from "../RangeInfo/RangeInfo";
import { Node } from "@sumit-platforms/slate";

interface RangeMediaVideoProps {
  children?: React.ReactNode;
  isPlaceholder?: boolean;
  isElementSelected?: boolean;

  element: SubtitleRangeElement;
  index: number;
}

export interface RangeMediaRef {
  focusCaption: () => void;
}
const RangeMediaVideo = (
  {
    children,
    element,
    isPlaceholder,
    isElementSelected,
    index,
  }: RangeMediaVideoProps,
  ref: React.Ref<RangeMediaRef>
) => {
  const currTime = useRecoilValue(currentTimeState);
  const disabled = useRecoilValue(isDisabledState);
  const direction = useRecoilValue(directionState);
  const editor = useSlateStatic() as CustomEditor;
  const isEditorFocused = useFocused();

  const getShouldHideCaption = useCallback(() => {
    if (!currTime) return true;
    if (!children) return true;
    if (editor.isContentScrolling) return false;

    if (isPlaceholder) return true;

    const timePassed =
      currTime > element.range.et || currTime < element.range.st;

    return timePassed;
  }, [currTime, children, editor, element, isPlaceholder]);

  const shouldHideCaption = getShouldHideCaption();

  const focusCaption = useCallback(() => {
    if (shouldHideCaption) return;

    const idx = EditorService.getRangeIndex({ editor, element });
    if (!_.isNumber(idx)) return;
    if (
      (editor.children[idx] as SubtitleRangeElement).type ===
      "subtitlePlaceholder"
    ) {
      return;
    }
    if (editor.selection?.anchor.path[0] !== idx) {
      editor.deselect();
      editor.select({
        anchor: { path: [idx], offset: 0 },
        focus: { path: [idx], offset: 0 },
      });
    }
  }, [editor, element, shouldHideCaption]);

  const handleDoubleClick = useCallback(() => {
    EditorService.jumpToSlateWord(editor);
  }, [editor]);

  useImperativeHandle(ref, () => ({ focusCaption }), [focusCaption]);

  return (
    <div
      id={"videoPlayerContainer"}
      className={classNames("videoContainer", {
        error: !!element.range.validation && !editor.isBetweenRanges,
        outOfDuration:
          element.range.validation?.errors?.is_range_out_of_duration,
      })}
    >
      <div
        className={classNames("videoSubtitles", {
          hide: shouldHideCaption,
          disabled,
        })}
        onDoubleClick={handleDoubleClick}
      >
        {children}
      </div>
      <div className="mediaInfoContainer">
        <RangeCount index={index} isPlaceholder={isPlaceholder} />

        <RangeInfo
          validation={element.range.validation}
          text={Node.string(element)}
          isFocused={!!isElementSelected && isEditorFocused}
          direction={direction}
          disabled={disabled}
          isPlaceholder={isPlaceholder}
        />
      </div>
    </div>
  );
};
export default forwardRef(RangeMediaVideo);
