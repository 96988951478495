import config from "../../config";
import { useQuery } from "@tanstack/react-query";
import { Card, CardContent } from "@mui/material";
import { useAuth } from "@sumit-platforms/ui-bazar/hooks";

import { getOverview } from "../../services/clientOverviewService";
import { clientStore } from "../../store/client";

import ProjectsAreaChart from "./components/ProjectsAreaChart/ProjectsAreaChart";
import ProjectsPieChart from "./components/ProjectsPieChart/ProjectsPieChart";
import Stats from "./components/Stats/Stats";
import TranscriptionHours from "./components/TranscriptionHours/TranscriptionHours";
import Welcome from "./components/Welcome/Welcome";
import MeetingSchedule from "./components/MeetingSchedule/MeetingSchedule";

import "./Home.scss";

const projects = [
  { value: "1", label: "Project 1" },
  { value: "2", label: "Project 2" },
];

const transformMonthlyDurations = (result: any) => {
  const monthlyDurationsArray: {
    name: string;
    [projectName: string]: number | string;
  }[] = [];
  const monthlyDurationsByMonth: {
    [month: string]: { [projectName: string]: number };
  } = {};

  const allProjectNames: string[] = Array.from(
    new Set(result.map((project: any) => project.name))
  );

  result.forEach((project: any) => {
    project.monthlyDurations.forEach((monthlyDuration: any) => {
      const { month, totalDuration } = monthlyDuration;

      if (!monthlyDurationsByMonth[month]) {
        monthlyDurationsByMonth[month] = {};
      }

      monthlyDurationsByMonth[month][project.name] = Number(totalDuration);
    });
  });

  for (const [month, projects] of Object.entries(monthlyDurationsByMonth)) {
    const monthData: { name: string; [projectName: string]: number | string } =
      {
        name: month,
      };

    allProjectNames.forEach((projectName: string) => {
      monthData[projectName] = projects[projectName] || 0;
    });

    monthlyDurationsArray.push(monthData);
  }

  monthlyDurationsArray.sort(
    (a, b) => new Date(a.name).getTime() - new Date(b.name).getTime()
  );

  return monthlyDurationsArray;
};
const Home = () => {
  const { user } = useAuth({ config });
  const { client } = clientStore();
  const { data } = useQuery({
    queryKey: ["clientOverview"],
    queryFn: () => getOverview({ idClient: client!.idClient }),
    enabled: !!client,
  });
  const projectsStats =
    data?.top4ProjectsDurationByMonth?.map((project: any) => ({
      name: project.name,
      value: project.totalDuration,
    })) || [];
  const stats = {
    files: {
      total: data?.mediaStats?.general?.totalMediaCount,
      lastMonth: data?.mediaStats?.last30Days?.totalMediaCount,
    },
    minutes: {
      total: data?.mediaStats?.general?.totalDuration,
      lastMonth: data?.mediaStats?.last30Days?.totalDuration,
    },
    searches: {
      total: 0,
      lastMonth: 0,
    },
  };
  const areaChartData = data?.top4ProjectsDurationByMonth
    ? transformMonthlyDurations(data.top4ProjectsDurationByMonth)
    : undefined;

  return (
    <div className="Home">
      <div className="firstRow">
        <div className="welcomeWrapper">
          <Welcome userName={user?.firstName} />
        </div>
        {/* <div className="statsWrapper">
          <Stats
            files={stats.files}
            minutes={stats.minutes}
            searches={stats.searches}
          />
        </div>
        <div className="talkWithUsWrapper">
          <Card sx={{ flex: 1 }}>
            <CardContent></CardContent>
          </Card>
        </div> */}
      </div>
      <div className="secondRow">
        {/* <div className="column">
          <div className="transcriptionHoursWrapper">
            <TranscriptionHours
              projects={projects}
              onChange={(v) => console.log(v)}
            />
          </div>
          <div className="projectsPieChartWrapper">
            <ProjectsPieChart projects={projectsStats} />
          </div>
          <div className="areaChartWrapper">
            {areaChartData && <ProjectsAreaChart data={areaChartData} />}
          </div>
        </div> */}
        <div className="column">
          <MeetingSchedule />
        </div>
      </div>
    </div>
  );
};

export default Home;
