import React, {
  ReactChild,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  // MouseEvent,
  // Ref,
} from "react";
import _ from "lodash";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBackward,
  faDownload,
  // faCompress,
  faForward,
  faGripLinesVertical,
  faListMusic,
  faPauseCircle,
  faPlayCircle,
  faRedo,
  // faTachometer,
  faTachometerAlt,
  faVideoSlash,
} from "@fortawesome/pro-light-svg-icons";
// import TimeFormat from "hh-mm-ss";
// import Draggable from "react-draggable";
import { Resizable } from "re-resizable";

import {
  EditorFeatureFlags,
  JobTCOffsets,
  Media,
  ShortcutAction,
} from "@sumit-platforms/types";
import { getTimecodeFromSeconds } from "@sumit-platforms/ui-bazar/utils";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
// import { LoggedInUser } from "../../models/user";

import MediaService from "../../services/MediaService";
import TimeService from "../../services/TimeService";
// import FeatureFlagsService from "../../services/FeatureFlagsService";
import { IconButton } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentTimeState,
  featureFlagsState,
  fpsState,
  isDisabledState,
  isMediaPanelOpenState,
  karaokeState,
  mediaErrorState,
} from "../../store/states";

import "./MediaPlayer.scss";
import EditorService from "../../services/EditorService";
import { CustomEditor } from "../../types";

interface MediaPlayerV3 {
  direction: "ltr" | "rtl";
  mediaSources: Media[];
  initialPlaybackPosition: number;
  currentCaption: any;
  displayText?: boolean;
  onCurrentTimeUpdate?: (currTime: number) => void;
  subtitlesData?: string;
  offsets?: { [key: string]: number };
  isStreaming?: boolean;
  children?: ReactChild;
  jobId: string | number;
  tcOffsets?: JobTCOffsets;
  downloadMedia?: (idMedia: number) => Promise<any>;
  openVideoOnStart?: boolean;
  onMediaDurationLoad?: (duration: number) => void;
}

const TranscriptMediaPlayer = ({
  direction,
  mediaSources,
  onCurrentTimeUpdate,
  currentCaption,
  initialPlaybackPosition = 0,
  subtitlesData,
  displayText = false,
  offsets,
  isStreaming,
  children,
  jobId,
  tcOffsets,
  downloadMedia,
  openVideoOnStart,
  onMediaDurationLoad,
}: MediaPlayerV3) => {
  const [isLoading, setIsLoading] = useState(false);
  const featureFlags = useRecoilValue(featureFlagsState);
  const isReadOnly = useRecoilValue(isDisabledState);
  const karaokeMode = useRecoilValue(karaokeState);
  const [, setCurrentTime] = useRecoilState(currentTimeState);
  const [mediaError, setMediaError] = useRecoilState(mediaErrorState);
  //MEMOIZED CONSTS
  const videoContainerId = useMemo(() => "videoPlayerContainer", []);
  const maxPlayerWidth = useMemo(() => 650, []);
  const maxPlayerHeight = useMemo(() => 380, []);
  const minPlayerWidth = useMemo(() => 380, []);
  const minPlayerHeight = useMemo(() => 120, []);
  const initialdimensions = useMemo(
    () => ({
      width: 455,
      height: 280,
    }),
    []
  );

  //SELECTORS
  // const { playerRewind, playerForward, playerRewindResume } = useSelector(
  //   (state: AppState) => state.userStore.settings
  // );
  const playerRewind = useMemo(() => 3, []);
  const playerForward = useMemo(() => 3, []);
  const playerRewindResume = useMemo(() => 0, []);

  //STATES
  const [currentCaptionsArrs, setCurrentCaptionsArrs] = useState<string[][]>(
    []
  );
  const [fps, setFps] = useRecoilState(fpsState);
  const [isOpen, setIsOpen] = useRecoilState(isMediaPanelOpenState);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [mediaLength, setMediaLength] = useState(0);
  const [currSpeed, setCurrSpeed] = useState<number>(1);
  const [showWaveForm, setShowWaveForm] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [isSourceListOpen, setIsSourceListOpen] = useState<boolean>(false);
  const [isDraggable, _setIsDraggable] = useState<boolean>(false);
  const timerEl = useRef<HTMLDivElement>(null);
  const timerTcEl = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [selectedMedia, _setSelectedMedia] = useState<Media>(
    mediaSources.find((media: Media) => media.mimetype.includes("video")) ||
      mediaSources[0]
  );

  const [dimensions, setdimensions] = useState<{
    width: number;
    height: number;
  }>(initialdimensions);

  const hasVideo = useMemo(() => {
    return selectedMedia?.mimetype.includes("video") || false;
  }, [selectedMedia]);

  const renewWaveForm = (): Promise<void> => {
    return new Promise((res, rej) => {
      try {
        const tempCurrTime = MediaService?.currentTime
          ? MediaService.currentTime
          : 0;
        setShowWaveForm(false);
        setTimeout(() => {
          setShowWaveForm(true);
          setTimeout(() => {
            MediaService.setOffset(tempCurrTime);
            res();
          }, 100);
        }, 50);
      } catch (err) {
        rej(err);
      }
    });
  };

  const setThrottledCurrTime = _.throttle((time: number) => {
    setCurrentTime(time);
  }, 250);

  const setCurrTime = (time: number, _tcOffsets: JobTCOffsets) => {
    const currTime = MediaService?.currentTime || 0;
    if (timerEl.current) {
      timerEl.current.innerHTML = TimeService.getTimeStringFromSecs(time);
    }
    if (timerTcEl.current) {
      timerTcEl.current.innerHTML = getTimecodeFromSeconds(time, {
        tcOffsets: _tcOffsets,
      });
    }
    if (isReadOnly && karaokeMode) {
      setThrottledCurrTime(currTime);
    }

    onCurrentTimeUpdate && onCurrentTimeUpdate(currTime);
  };

  //EVENTS
  useEffect(() => {
    renewWaveForm();
  }, [isDraggable]);

  useEffect(() => {
    const _renewWaveFormAfterResizing = async () => {
      if (isDraggable) {
        await renewWaveForm();
      }
    };
    _renewWaveFormAfterResizing();
  }, [dimensions]);

  useEffect(() => {
    setTimeout(() => {
      if (isMounted) {
        setShowWaveForm(true);
      }
    }, 1000);
    setIsOpen(openVideoOnStart || false);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    //I'VE TRIED TO WORK WITH KEY PRESS HOOK BUT I NEED TO DO COMBINE PRESSING
    const keyStrokesHandlerMPV3 = async (e: KeyboardEvent) => {
      if (!e.ctrlKey) return;
      if (e.code == "ArrowUp") {
        setIsOpen(true);
      } else if (e.code == "ArrowDown") {
        setIsOpen(false);
      }
    };
    if (!featureFlags?.useNewKeyboardShortcuts) {
      document.addEventListener("keydown", keyStrokesHandlerMPV3, false);
    }
    return () => {
      if (!featureFlags?.useNewKeyboardShortcuts) {
        document.removeEventListener("keydown", keyStrokesHandlerMPV3);
      }
    };
  }, [playerRewind, playerForward, playerRewindResume]);

  useEffect(() => {
    const splitCurrentCaption = () => {
      let currentCaptionWordsArr = [];
      if (_.isString(currentCaption)) {
        currentCaptionWordsArr = [[...currentCaption.split(" ")]];
      } else {
        currentCaptionWordsArr = _.map(currentCaption, (range) =>
          _.map(range, (word) => word.word)
        );
      }
      let splitCurrentCaptionWordsArr: string[][] = [];
      for (const caption of currentCaptionWordsArr) {
        if (caption.length > 8) {
          const firstRow = caption.slice(0, caption.length / 2);
          const secondRow = caption.slice(caption.length / 2);
          splitCurrentCaptionWordsArr = splitCurrentCaptionWordsArr.concat(
            [firstRow],
            [secondRow]
          );
        } else {
          splitCurrentCaptionWordsArr.push(caption);
        }
      }
      setCurrentCaptionsArrs(splitCurrentCaptionWordsArr);
    };
    splitCurrentCaption();
  }, [currentCaption]);

  const onMediaLoadError = useCallback(
    (src: string) => {
      setMediaError({ ...mediaError, [src]: true });
    },
    [setMediaError, mediaError]
  );

  const setOffset = useCallback(
    (media: Media) => {
      if (offsets) {
        const _offset = offsets[media.name]
          ? Math.abs(offsets[media.name] / 1000)
          : 0;
        return _offset;
      }
      return;
    },
    [offsets]
  );

  useEffect(() => {
    // Updating the setCurrTime fn due to closure problem
    MediaService.setSetCurrTime(setCurrTime, setOffset(selectedMedia));
  }, [selectedMedia, setOffset]);

  useEffect(() => {
    if (!selectedMedia) return;
    const tempCurrTime = MediaService?.currentTime
      ? MediaService.currentTime
      : 0;
    MediaService.initializeMedia({
      media: selectedMedia,
      setMedia: setSelectedMedia,
      setCurrTime,
      setIsPlaying,
      setMediaLength,
      mediaLength,
      offset: setOffset(selectedMedia),
      autoPlay,
      isStreaming,
      jobId: jobId.toString(),
      fps,
      onMediaLoadError,
      onMediaDurationLoad,
    });
    setIsSourceListOpen(false);
    setTimeout(() => {
      MediaService.setOffset(tempCurrTime);
      setAutoPlay(true);
    }, 100);
    if (subtitlesData && displayText) {
      MediaService.setSubtitles(subtitlesData);
    }
    return () => MediaService.pause();
  }, [selectedMedia, fps]);

  useEffect(() => {
    if (!selectedMedia) return;
    if (subtitlesData && displayText) {
      MediaService.setSubtitles(subtitlesData);
    }
  }, [subtitlesData]);

  const setSelectedMedia = useCallback(
    (media: Media) => {
      const fps = media.fps || 25;
      setFps(fps);
      _setSelectedMedia(media);
      setMediaError({});
    },
    [setFps, setMediaError]
  );
  const handleTogglePlayerKeystroke = useCallback(
    (e: React.KeyboardEvent, isOpen: boolean) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(isOpen);
    },
    []
  );

  const handleDownloadMedia = useCallback(
    async (e: any, idMedia: number) => {
      e.stopPropagation();
      if (!idMedia || !downloadMedia) return;
      try {
        setIsLoading(true);
        await downloadMedia(idMedia);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [downloadMedia]
  );

  //RENDERING
  const renderSubtitles = useCallback(() => {
    return (
      <div className="videoSubtitles">
        {_.map(currentCaptionsArrs, (caption) => (
          <span
            key={caption + ""}
            className={classNames("rangeString", direction, {
              liveCaptionEditing: _.isString(currentCaption),
            })}
          >
            {caption.join(" ")}
          </span>
        ))}
      </div>
    );
  }, [currentCaption, currentCaptionsArrs, direction]);
  const renderTime = useCallback(() => {
    return (
      <div className="mediaTimeRange">
        {MediaService.status === "buffering" ? (
          <div className="curr-time unselectable">Buffering...</div>
        ) : (
          <>
            {tcOffsets && tcOffsets[0] && tcOffsets[0][1] > 0 && (
              <div className="tc-offset-time unselectable" ref={timerTcEl} />
            )}
            <div className="curr-time unselectable" ref={timerEl} />
            <div className="">/</div>
            <div className="curr-time unselectable">
              {TimeService.getTimeStringFromSecs(mediaLength)}
            </div>
          </>
        )}
      </div>
    );
  }, [mediaLength, tcOffsets]);

  const renderTimeSpeed = useCallback(() => {
    return (
      <div className="speed-control">
        <div className="speed-control-section">
          {[2, 1.75, 1.5, 1.25, 1, 0.75, 0.5].map((speed: number) => {
            return (
              <div
                key={speed}
                className="speed"
                onClick={() => {
                  MediaService.changeSpeed(speed);
                  setCurrSpeed(speed);
                }}
              >
                {speed}
              </div>
            );
          })}
        </div>

        <FontAwesomeIcon icon={faTachometerAlt} />
        <p className="unselectable">{currSpeed}</p>
      </div>
    );
  }, [currSpeed]);

  const goForward = useCallback(() => {
    MediaService.playRelative(playerForward || 5);
  }, [playerForward]);

  const replay = useCallback(() => {
    MediaService.setOffset(0);
  }, []);

  useKeyboardShortcuts({
    handlers: {
      SHOW_PLAYER_CONTROLS: (e) => handleTogglePlayerKeystroke(e, true),
      HIDE_PLAYER_CONTROLS: (e) => handleTogglePlayerKeystroke(e, false),
    },
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  const goRewind = useCallback(() => {
    MediaService.playRelative((playerRewind || 5) * -1);
  }, []);

  const renderMediaPlayerClasses = useCallback((): string => {
    if (isDraggable) {
      return "draggable";
    }

    return !isOpen ? "close" : "";
  }, [isDraggable, isOpen]);

  // CONTROLLERS
  // const goRewindFast = () => {
  //   MediaService.playRelative((playerRewind || 5) * -1 * 10);

  // };
  // const goForwardFast = () => {

  //   MediaService.playRelative((playerForward || 5) * 10);

  // };

  return (
    // <Draggable
    //   handle={`#${videoContainerId}`}
    //   bounds={"html"}
    //   axis={isDraggable ? "both" : "none"}
    // >
    <div
      className={classNames("MediaPlayer", direction, {
        draggable: isDraggable,
        close: !isOpen,
      })}
      style={isDraggable ? { width: "fit-content" } : {}}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Resizable
        style={
          //When in draggable state, we need media window to be in `initial` position to reclaim its space
          isDraggable
            ? { position: "initial" }
            : {
                position: "absolute",
              }
        }
        //Style of right and left grips wrapper
        handleWrapperStyle={{
          height: dimensions.height,
          position: "absolute",
          //Same as enable
          left: direction === "ltr" || isDraggable ? "10px" : "auto",
          right: direction === "rtl" && !isDraggable ? "10px" : "auto",
        }}
        //Grips
        handleComponent={{
          right: (
            <div className={`gripResize`}>
              <FontAwesomeIcon icon={faGripLinesVertical} />
            </div>
          ),
          left: (
            <div className={`gripResize`}>
              <FontAwesomeIcon icon={faGripLinesVertical} />
            </div>
          ),
        }}
        lockAspectRatio={true}
        maxWidth={maxPlayerWidth}
        maxHeight={maxPlayerHeight}
        minWidth={minPlayerWidth}
        minHeight={minPlayerHeight}
        defaultSize={initialdimensions}
        className={`media-player-v3-content ${!isOpen ? "close" : ""}`}
        //Enable left and right grips accourding to draggable state and direction
        enable={{
          //Same as handle wrapper style
          left: direction === "ltr" || isDraggable ? true : false,
          right: direction === "rtl" && !isDraggable ? true : false,
        }}
        onResizeStop={(
          event: any,
          direction: string,
          ref: HTMLElement,
          d: { width: number; height: number }
        ) => {
          setdimensions({
            width: dimensions.width + d.width,
            height: dimensions.height + d.height,
          });
        }}
      >
        <div id={videoContainerId} className="videoContainer">
          <div className="replay" onClick={replay}>
            <FontAwesomeIcon icon={faRedo} />
          </div>
          {renderSubtitles()}
          {!hasVideo && (
            <FontAwesomeIcon
              className="videoPlaceholder"
              icon={faVideoSlash}
            ></FontAwesomeIcon>
          )}
          <div
            className="list-sources"
            onClick={() => setIsSourceListOpen(!isSourceListOpen)}
          >
            <FontAwesomeIcon icon={faListMusic} />
          </div>
          {isSourceListOpen && (
            <div className={classNames(["list-sources-container"])}>
              {mediaSources.map((media: Media, i: number) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setSelectedMedia(media);
                    }}
                    className={classNames([
                      "sourceItem",
                      { selected: selectedMedia.idMedia === media.idMedia },
                    ])}
                  >
                    {featureFlags?.allowDownloadMedia && (
                      <IconButton
                        onClick={(e: any) => {
                          handleDownloadMedia(e, media.idMedia);
                        }}
                        className={"downloadIcon"}
                        disabled={isLoading}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </IconButton>
                    )}
                    <div>{media.name}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Resizable>
      <div className={`mediaPlayerV3Controls  ${!isOpen ? "close" : ""}`}>
        <div className="part-1 time">
          {renderTimeSpeed()}
          {renderTime()}
        </div>
        <div className="part-2 controllers">
          {/* <FontAwesomeIcon
              className="left"
              onClick={goRewindFast}
              icon={faFastBackward}
              size="2x"
            ></FontAwesomeIcon> */}
          <FontAwesomeIcon
            className="left"
            onClick={goRewind}
            icon={faBackward}
            size="2x"
          ></FontAwesomeIcon>
          <div
            onClick={() => {
              MediaService.togglePlay((playerRewindResume || 0) * -1);
            }}
            className="play-pause"
          >
            {isPlaying ? (
              <FontAwesomeIcon size="2x" icon={faPauseCircle} />
            ) : (
              <FontAwesomeIcon size="2x" icon={faPlayCircle} />
            )}
          </div>
          <FontAwesomeIcon
            className="right"
            onClick={goForward}
            icon={faForward}
            size="2x"
          ></FontAwesomeIcon>
          {/* <FontAwesomeIcon
              className="right"
              onClick={goForwardFast}
              icon={faFastForward}
              size="2x"
            ></FontAwesomeIcon> */}
        </div>
        <div className="part-3">
          {/* <FontAwesomeIcon
            className={`minimize`}
            onClick={() => {
              setIsDraggable(!isDraggable);
            }}
            icon={faCompress}
            size="2x"
          ></FontAwesomeIcon> */}
          {!isDraggable && (
            <FontAwesomeIcon
              onClick={() => setIsOpen(!isOpen)}
              className={`toggle ${!isOpen ? "close" : ""}`}
              icon={faAngleDown}
              size="2x"
            ></FontAwesomeIcon>
          )}
        </div>
      </div>
      <div className={`media-player-v3-arranger ${!isOpen ? "close" : ""}`}>
        {showWaveForm && children}
      </div>
    </div>
    // </Draggable>
  );
};

export default TranscriptMediaPlayer;
