import React, { useState } from "react";
import {
  Alert as MuiAlert,
  AlertProps,
  Collapse,
  IconButton,
} from "@mui/material";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Alert.scss";

export const Toast = ({ children, ...rest }: AlertProps) => {
  const [open, setOpen] = useState(true);
  return (
    <Collapse in={open}>
      <MuiAlert
        className={"Toast"}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            className={"closeAlertButton"}
            onClick={() => {
              setOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faClose} size={"sm"} />
          </IconButton>
        }
        sx={{ mb: 2 }}
        {...rest}
      >
        {children}
      </MuiAlert>
    </Collapse>
  );
};
