import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { NewTimerPicker } from "../NewTimerPicker/NewTimerPicker";
import { SubtitleRangeElement } from "../../types";
import EditorService from "../../services/EditorService";
import MediaService from "../../services/MediaService";
import RangeInfo from "../RangeInfo/RangeInfo";

import { useRecoilValue } from "recoil";
import { directionState, isDisabledState } from "../../store/states";
import { useFocused, useSlateStatic } from "@sumit-platforms/slate-react";
import { Node } from "@sumit-platforms/slate";
import { RangeCount } from "./RangeCount";
import { CustomEditor } from "../../types";

import "./RangeSubtitles.scss";

interface Props {
  attributes: any;
  children?: React.ReactNode;
  handleBlur?: (rangeIndex?: number) => void;
  startIndex?: number | null;
  renderIndex?: number | null;
  isElementSelected?: boolean;
}

const RangeSubtitles = ({
  attributes,
  children,
  handleBlur,
  startIndex,
  renderIndex,
  isElementSelected,
}: Props) => {
  if (!_.isNumber(startIndex) || !_.isNumber(renderIndex)) {
    throw new Error("startIndex and renderIndex must be numbers");
  }

  const editor = useSlateStatic() as CustomEditor;
  const disabled = useRecoilValue(isDisabledState);
  const direction = useRecoilValue(directionState);
  const isEditorFocused = useFocused();

  const element = editor.children[
    (startIndex || 0) + (renderIndex || 0)
  ] as SubtitleRangeElement;

  const rangeRef = useRef<HTMLDivElement | null>(null);
  const isFocusedPrevValue = useRef(false);

  const onBlur = useCallback(() => {
    const rangeIndex = EditorService.getRangeIndex({ element, editor });
    if (handleBlur) {
      handleBlur(rangeIndex);
    }
  }, [editor, element, handleBlur]);

  const handleTimePickerBlur = useCallback(
    (time: number, position: "start" | "end") => {
      EditorService.updateRangeTime({
        editor,
        time,
        position,
        rangeIndex: EditorService.getRangeIndex({ element, editor }),
        element,
      });
    },
    [editor, element]
  );

  const handleAddFrames = useCallback(
    (
      framesToAdd: number,
      position: "start" | "end",
      limit?: {
        min?: number;
        max?: number;
      }
    ) => {
      EditorService.handleAddFrames({
        editor,
        framesToAdd,
        position,
        rangeIndex: EditorService.getRangeIndex({ element, editor }),
        element,
        disabled,
        limit,
      });
    },
    [disabled, editor, element]
  );

  useEffect(() => {
    if (isFocusedPrevValue.current && !isElementSelected) {
      onBlur();
    }

    isFocusedPrevValue.current = !!isElementSelected;
  }, [isElementSelected]);

  const handleDoubleClick = useCallback(() => {
    EditorService.jumpToSlateWord(editor);
  }, [editor]);

  const onRangeClick = useCallback(() => {
    if (MediaService.isPlaying) return;
    if (!editor.isBetweenRanges) return;
    if (renderIndex > 1) return;

    // This is fix for wired bug that when the user is between ranges and try to type on first range, he loses focus.
    // to prevent that we paginate the subtitle

    EditorService.paginateSubtitle({
      editor,
      to: "next",
      preventCursorManipulation: true,
    });
  }, [editor, renderIndex]);

  return (
    <div
      className={classNames(`SlateRangeSubtitle`, direction, {
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
      ref={rangeRef}
      onClick={onRangeClick}
      {...attributes}
    >
      <div
        className={classNames("subtitleRangeTimes subtitles")}
        contentEditable={false}
      >
        <NewTimerPicker
          className={classNames({
            overlapping:
              element.range.validation?.errors?.overlapping_prev ||
              element.range.validation?.errors?.start_after_end,
            outOfDuration:
              element.range.validation?.errors?.is_range_out_of_duration,
          })}
          isFocused={isElementSelected && isEditorFocused}
          value={element.range.st}
          deductTime={() => handleAddFrames(-1, "start")}
          addTime={() =>
            handleAddFrames(1, "start", { max: element.range.et - 0.01 })
          }
          disabled={disabled}
          fps={MediaService.frameRate}
          tcOffsets={MediaService.tcOffsets}
          handleBlur={(time: number) => handleTimePickerBlur(time, "start")}
          maxLimit={(MediaService?.media?.duration || 0) + 3}
        />
        <NewTimerPicker
          className={classNames({
            overlapping:
              element.range.validation?.errors?.overlapping_next ||
              element.range.validation?.errors?.start_after_end,
            outOfDuration:
              element.range.validation?.errors?.is_range_out_of_duration,
          })}
          isFocused={isElementSelected && isEditorFocused}
          value={element.range.et}
          deductTime={() =>
            handleAddFrames(-1, "end", { min: element.range.st + 0.01 })
          }
          addTime={() =>
            handleAddFrames(1, "end", {
              max: (MediaService?.media?.duration || 0) + 3,
            })
          }
          disabled={disabled}
          fps={MediaService.frameRate}
          tcOffsets={MediaService.tcOffsets}
          handleBlur={(time: number) => handleTimePickerBlur(time, "end")}
          maxLimit={(MediaService?.media?.duration || 0) + 3}
          minLimit={element.range.st + MediaService.frameLength} // Minimum 1 frame above st
        />
      </div>

      <div
        className={classNames("textContainer", {
          error: !_.isEmpty(element.range.validation?.errors),
          passed: renderIndex === 0 || disabled,
          outOfDuration:
            element.range.validation?.errors?.is_range_out_of_duration,
        })}
      >
        <RangeCount index={startIndex + renderIndex + 1} />
        <div
          onDoubleClick={handleDoubleClick}
          className={classNames("rangeText", { disabled })}
        >
          {children}
        </div>
        <RangeInfo
          validation={element.range.validation}
          text={Node.string(element)}
          isFocused={!!isElementSelected && isEditorFocused}
          direction={direction}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default RangeSubtitles;
