import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { Button as LoadingButton } from "../../core/Button/Button";
import { useTranslation } from "react-i18next";
import { MFAHeader } from "../../pages/MFA/MfaHeader";
import { OTPInput } from "../OtpInput/OtpInput";

import "../../pages/MFA/mfa.scss";

interface Props {
  verifySMSCode: (code: string) => Promise<void>;
  resendCode: () => Promise<void>;
  logOut?: () => Promise<void>;
  validateMfaSession: () => Promise<{
    email: string | null;
    phone: string | null;
  } | null>;
  showHeader?: boolean;
}
const timeTimeout = 30;

export const VerifySMSCode: React.FC<Props> = ({
  verifySMSCode,
  resendCode,
  logOut,
  validateMfaSession,
  showHeader = true,
}: Props) => {
  const { t } = useTranslation();

  const [otp, setOtp] = useState("");
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isResendCodeLoading, setIsResendCodeLoading] = useState(false);
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(timeTimeout);
  const [canResend, setCanResend] = useState(false);
  const [maskedPhone, setMaskedPhone] = useState("");

  const _validateMfaSession = useCallback(async () => {
    const response = await validateMfaSession();
    if (response?.phone) {
      setMaskedPhone(response.phone);
    }
  }, []);

  useEffect(() => {
    _validateMfaSession();
  }, []);

  const handleVerifyCode = async (otp: string) => {
    setIsVerifyLoading(true);
    if (otp.length !== 6) {
      setIsVerifyLoading(false);
      return;
    }
    setError("");
    await verifySMSCode(otp)
      .catch((err) => {
        console.error(err);
        setError(
          `${t("mfa_verify_error")}\n${t(err?.response?.data?.error) || ""}`
        );
      })
      .finally(() => {
        setIsVerifyLoading(false);
      });
  };

  const handleOtpInputChange = useCallback(
    (value: string) => {
      setOtp(value);
      if (value.length === 6) {
        handleVerifyCode(value);
      }
    },
    [handleVerifyCode]
  );

  const handleResendCode = async () => {
    setCanResend(false);
    setTimer(timeTimeout);
    setIsResendCodeLoading(true);
    setError("");
    setMessage("");
    try {
      await resendCode();
      setMessage(t("mfa_resend_code_sent"));
    } catch (e) {
      console.error(`Failed to resend code.`, e);
      setMessage(t("mfa_resend_code_sent_failed"));
    } finally {
      setIsResendCodeLoading(false);
    }
  };

  const handleLogout = useCallback(async () => {
    setIsSignoutLoading(true);
    try {
      if (logOut) await logOut();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSignoutLoading(false);
    }
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!canResend) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval!);
            setCanResend(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [canResend]);

  return (
    <>
      {showHeader && <MFAHeader handleLogout={handleLogout} />}
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        align="center"
        color="text.primary"
      >
        {t("mfa_verify_sms_title")}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        align="center"
        color="text.secondary"
      >
        {t("mfa_verify_sms_instruction")}
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          <OTPInput value={otp} onChange={handleOtpInputChange} />
        </Box>
        {maskedPhone && (
          <Typography variant="body2" align="center" color="text.secondary">
            {t("mfa_send_to_phone", { phone: maskedPhone })}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: 30,
          }}
          my={1}
          className="codeError"
        >
          <Typography variant="body2" gutterBottom align="center" color="error">
            {t(error || "")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => handleVerifyCode(otp)}
            loading={isVerifyLoading}
            disabled={otp.length < 6 || isResendCodeLoading || isSignoutLoading}
          >
            {t("mfa_verify_code_button")}
          </LoadingButton>
        </Box>
      </Box>
      <Typography
        variant="body2"
        align="center"
        color="text.secondary"
        sx={{ mt: 4 }}
      >
        {t("mfa_didnt_receive_code")}{" "}
        <Button
          color="primary"
          onClick={handleResendCode}
          disabled={!canResend || isSignoutLoading}
          sx={{ textTransform: "none" }}
        >
          {canResend
            ? t("mfa_resend_code")
            : `${t("mfa_resend_in", { time: timer })}`}
        </Button>
      </Typography>
      <Typography
        variant="body2"
        align="center"
        color="text.secondary"
        sx={{ height: 20 }}
      >
        {message || ""}
      </Typography>
    </>
  );
};
