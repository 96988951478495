import React from "react";
import { ThemeProvider } from "@mui/material";
import { bazarTheme } from "../../bazar-theme";
import { VerifyOTPCode } from "../../../index";

import "./mfa.scss";

interface Props {
  verifyOTPCode: (code: string) => Promise<void>;
  logOut?: () => Promise<void>;
  validateMfaSession: () => Promise<{
    email: string | null;
    phone: string | null;
  } | null>;
}

export const VerifyOTPCodePage: React.FC<Props> = ({
  verifyOTPCode,
  logOut,
  validateMfaSession,
}: Props) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="VerifySMSCode AuthPage MFA">
        <div className="authContainer">
          <VerifyOTPCode
            verifyOTPCode={verifyOTPCode}
            validateMfaSession={validateMfaSession}
            logOut={logOut}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};
