import React from "react";
import { ThemeProvider } from "@mui/material";
import { bazarTheme } from "../../bazar-theme";
import { VerifySMSCode } from "../../../index";

import "./mfa.scss";

interface Props {
  verifySMSCode: (code: string) => Promise<void>;
  resendCode: () => Promise<void>;
  logOut?: () => Promise<void>;
  validateMfaSession: () => Promise<{
    email: string | null;
    phone: string | null;
  } | null>;
}

export const VerifySMSCodePage: React.FC<Props> = ({
  verifySMSCode,
  resendCode,
  logOut,
  validateMfaSession,
}: Props) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="VerifyOTPCode AuthPage MFA">
        <div className="authContainer">
          <VerifySMSCode
            verifySMSCode={verifySMSCode}
            resendCode={resendCode}
            logOut={logOut}
            validateMfaSession={validateMfaSession}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};
