import { Trans } from "react-i18next";

export const ReAuthNote = () => {
  return (
    <>
      <p className={"noteMessage"}>
        <Trans
          i18nKey={"session_renew_note"}
          components={{
            br: <br />,
          }}
        />
      </p>
    </>
  );
};
