import { JobRange, Word } from "@sumit-platforms/types";

// Spreading word times evenly by start and end times
const getWordsByRangeTimes = (
  words: Word[],
  start: number,
  end: number
): Word[] => {
  const rangeDuration = end - start;
  const wordDuration = Math.abs(rangeDuration / words.length);

  const updatedWords = [];

  for (const [i, word] of words.entries()) {
    const wordStart = wordDuration * i + start;
    const wordEnd = i === words.length - 1 ? end : wordStart + wordDuration;

    const wordObj = {
      ...word,
      start_time: Number(wordStart.toFixed(2)),
      end_time: Number(wordEnd.toFixed(2)),
    };
    updatedWords.push(wordObj);
  }
  return updatedWords;
};

const createEmptyRange = (times = { start: 0, end: 1 }, rangeIx?: number) => {
  const et = times?.end || 0;
  const st = times?.start || 0;
  const range = {
    et: et,
    st: st,
    words: [
      {
        start_time: st,
        end_time: st + 1,
        line_ix: 0,
        range_ix: rangeIx || 0,
        text_edit: false,
        time_edit: false,
        word: "",
        speaker: "spk",
      },
    ],
  };
  return range as JobRange;
};

export { getWordsByRangeTimes, createEmptyRange };
