import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "./ContextMenu.scss";

export interface ContextOption {
  name: string;
  action: () => void;
  color?: string;
  disabled?: boolean;
  bullet?: { color: string };
  hide?: boolean;
}

export const ContextMenu = ({
  context,
  onClose,
  anchorEl,
  open,
  setIsOpen,
}: {
  onClose?: () => void;
  anchorEl: HTMLElement | null;
  open: boolean;
  setIsOpen: (open: boolean) => void;
  context: ContextOption[];
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      className="contextMenu"
      onClose={onClose}
      anchorEl={anchorEl}
      open={open}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {context
        .filter((item) => !item.hide)
        .map((option, i) => (
          <MenuItem
            disabled={option.disabled}
            sx={option.color ? { color: option.color } : {}}
            key={i}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (option.disabled) return;
              option.action();
              setIsOpen(!open);
            }}
          >
            {option.bullet && (
              <div
                style={{
                  background: option.bullet.color,
                  height: "0.7rem",
                  width: "0.7rem",
                  marginRight: "10px",
                  borderRadius: "4px",
                }}
              />
            )}
            {t(option.name)}
          </MenuItem>
        ))}
    </Menu>
  );
};
