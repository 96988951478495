import * as defaultAvatarPng from "./default/default_avatar.png";
import * as userSvg from "./default/user.svg";
import * as clientSvg from "./default/client.svg";
import * as drivePng from "./icons/drive.png";
import * as dropboxPng from "./icons/dropbox.png";
import * as gmailPng from "./icons/gmail.png";
import * as whatsappPng from "./icons/whatsapp.png";
import * as sumitLogoBlackPng from "./sumit/sumit_logo_black.png";
import * as sumitLogoShortPng from "./sumit/sumit_logo_short.png";
import * as sumitLogoWhitePng from "./sumit/sumit_logo_white.png";
import * as sumitLogoFullWhitePng from "./sumit/sumit_logo_full_white.png";
import * as extractDefaultThumbnailPng from "./default/default_image.png";
import * as loadingVideoPosterGif from "./default/loading_video.gif";
import * as replaceOneIconPng from "./icons/replace_one.png";
import * as replaceAllIconPng from "./icons/replace_all.png";
import * as AuthGoole from "./icons/google_authenticator.png";
import * as AuthMS from "./icons/ms_authenticator.png";
import * as AuthApple from "./icons/apple_authenticator.png";
import * as GoogleG from "./icons/google-g.png";

export const defaultAvatar = defaultAvatarPng.default;
export const userImage = userSvg.default;
export const clientImage = clientSvg.default;
export const driveIcon = drivePng.default;
export const dropboxIcon = dropboxPng.default;
export const gmailIcon = gmailPng.default;
export const sumitLogoBlack = sumitLogoBlackPng.default;
export const sumitLogoShort = sumitLogoShortPng.default;
export const sumitLogoWhite = sumitLogoWhitePng.default;
export const sumitLogoFullWhite = sumitLogoFullWhitePng.default;
export const whatsappIcon = whatsappPng.default;
export const extractDefaultThumbnail = extractDefaultThumbnailPng.default;
export const loadingVideoPoster = loadingVideoPosterGif.default;
export const replaceOneIcon = replaceOneIconPng.default;
export const replaceAllIcon = replaceAllIconPng.default;
export const AuthGooleIcon = AuthGoole.default;
export const AuthMSIcon = AuthMS.default;
export const AuthAppleIcon = AuthApple.default;
export const GoogleGIcon = GoogleG.default;
