import { MFAHeader } from "../../pages/MFA/MfaHeader";
import { useTranslation } from "react-i18next";

export const ReAuthHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <MFAHeader />
      <p className={"title"}>{t("session_renew_required_title")}</p>
      <p className={"subTitle"}>{t("session_renew_required_subtitle")}</p>
    </>
  );
};
