import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  JobRevisionsRequestParams,
  JobRevision,
  SaveTriggers,
} from "@sumit-platforms/types";
import { Modal } from "../../core/Modal/Modal";
import { Grid, Typography } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { useTranslation } from "react-i18next";
import { RestoreJob } from "../../core/RestoreJob/RestoreJob";
import { defaultJobRevisionsQuery } from "../../constants/default-queries";

interface JobRestoreModalProps {
  onApprove: (revision: JobRevision) => Promise<void>;
  onCancel: () => void;
  idJob: number;
  getJobRevisions: (
    query?: JobRevisionsRequestParams
  ) => Promise<undefined | JobRevision[]>;
  filterBy?: {
    trigger: JobRevision["trigger"][];
    isClientRevision?: JobRevision["isClientRevision"];
  };
}

export const JobRestoreModal = ({
  idJob,
  onApprove,
  onCancel,
  getJobRevisions,
  filterBy,
}: JobRestoreModalProps) => {
  const { t } = useTranslation();

  const [excludeSaveTriggers, setExcludeSaveTriggers] = useState(
    defaultJobRevisionsQuery.excludeSaveTriggers || []
  );
  const [revisions, setRevisions] = useState<JobRevision[]>([]);
  const [revisionsLoading, setRevisionsLoading] = useState(false);
  const [restoreJobLoading, setRestoreJobLoading] = useState(false);
  const [selectedRevision, setSelectedRevision] = useState<JobRevision | null>(
    null
  );

  const resetJobRevision = useMemo(
    () => revisions.find((rev) => !!rev.isBaseRevision),
    [revisions]
  );

  const filterRevisions = useCallback((revisions: JobRevision[]) => {
    const transcribeDoneTriggers = filterBy?.trigger || [];
    const filteredRevisions = revisions.filter((revision) => {
      const allowedTriggers = transcribeDoneTriggers.includes(revision.trigger);
      const clientRevision =
        revision.isClientRevision === (filterBy?.isClientRevision || false);
      return allowedTriggers || clientRevision;
    });
    return filteredRevisions;
  }, []);

  const getRelevantRevisions = useCallback(
    async ({ idJob, excludeSaveTriggers }: JobRevisionsRequestParams) => {
      let revisions =
        (await getJobRevisions({ idJob, excludeSaveTriggers })) || [];
      if (!_.isEmpty(filterBy)) {
        revisions = filterRevisions(revisions);
      }
      return revisions;
    },
    []
  );

  const updateRevisions = async ({
    idJob,
    excludeSaveTriggers,
  }: JobRevisionsRequestParams) => {
    setRevisionsLoading(true);
    const revisions = await getRelevantRevisions({
      idJob,
      excludeSaveTriggers,
    });
    setRevisions(revisions || []);
    setRevisionsLoading(false);
  };

  const init = async () => {
    await updateRevisions({ idJob, excludeSaveTriggers });
  };

  useEffect(() => {
    init();
  }, []);

  const handleResetJob = async () => {
    await handleOnApprove(resetJobRevision);
  };

  const handleOnApprove = async (revision?: JobRevision | null) => {
    setRestoreJobLoading(true);
    try {
      if (!revision) return;
      await onApprove(revision);
    } catch (e) {
      console.log("e :", e);
    } finally {
      setRestoreJobLoading(false);
    }
  };

  const autoSaveChecked = useMemo(() => {
    return !excludeSaveTriggers.includes(SaveTriggers.AUTO_SAVE);
  }, [excludeSaveTriggers]);

  const handleToggleAutoSaves = useCallback(() => {
    let triggers = [];
    if (!autoSaveChecked) {
      triggers = excludeSaveTriggers.filter(
        (method) => method !== SaveTriggers.AUTO_SAVE
      );
    } else {
      triggers = [...(excludeSaveTriggers || []), SaveTriggers.AUTO_SAVE];
    }
    setExcludeSaveTriggers(triggers);
    updateRevisions({ idJob, excludeSaveTriggers: triggers });
    setSelectedRevision(null);
  }, [autoSaveChecked, updateRevisions, idJob, excludeSaveTriggers]);

  const onRevisionChange = useCallback((revision: JobRevision) => {
    setSelectedRevision(revision);
  }, []);

  return (
    <Modal type={"info"} closeModal={onCancel}>
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        py={3}
        px={3}
        width={"35rem"}
        textAlign={"center"}
      >
        <Grid item pt={1}>
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("restore_job_confirm_modal_title")}
          </Typography>
        </Grid>
        <Grid item>
          <RestoreJob
            idJob={idJob}
            revisions={revisions}
            isLoading={revisionsLoading}
            onRevisionClick={onRevisionChange}
            chosenRevision={selectedRevision}
            handleToggleAutoSaves={handleToggleAutoSaves}
            autoSaveChecked={autoSaveChecked}
          />
        </Grid>
        <Grid item display="flex" justifyContent={"center"} gap={3} py={2}>
          {resetJobRevision && (
            <Button
              color={"warning"}
              sx={{ marginRight: "auto" }}
              onClick={handleResetJob}
              disabled={restoreJobLoading}
              infoTooltip={t("reset_job_tooltip") as string}
            >
              {t("reset_job") as string}
            </Button>
          )}
          <Button onClick={onCancel} variant="outlined">
            {t("cancel") as string}
          </Button>
          <Button
            loading={restoreJobLoading}
            onClick={() => handleOnApprove(selectedRevision)}
            color="primary"
            disabled={!selectedRevision}
          >
            {t("restore_job") as string}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
