import axios from "axios";

const googleAuthService = ({ config }: { config: any }) => {
  const endpoint = `${config.server.host}/${config.server.auth}/google`;
  const authenticateWithCode = async (code: string) => {
    const user = await axios.post(`${endpoint}/exchange-code`, {
      code,
      redirectUrl: config.envUrl,
    });

    return user.data.userInfo;
  };
  return {
    authenticateWithCode,
  };
};

export default googleAuthService;
