import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCountries, getLanguages } from "../../utils/locales";
import { emailRegex, languagesRegex } from "../../utils/regex";
import { Form, FormField } from "../../core/Form/Form";
import { StepsForm } from "../StepsForm/StepsForm";

import "./SignUpForm.scss";

export const SignUpForm = ({
  authService,
  invitationDetails,
}: {
  authService: any;
  invitationDetails?: any;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validateEnglish = (input: string) => {
    if (!languagesRegex.en.test(input)) return t("accept_only_en");
    return true;
  };

  const links = [
    {
      preLinkPhrase: "agree_to_terms1",
      url: "https://sumit-ai.com/terms-of-use/",
      linkName: "agree_to_terms2",
    },
    {
      preLinkPhrase: "agree_to_terms3",
      url: "https://sumit-ai.com/privacy-policy/",
      linkName: "agree_to_terms4",
    },
  ];

  type PhoneDetailes = {
    phoneNumber: string;
    countryCode: string;
    formatted: string;
  };

  type userDetailsTypes = {
    firstName: string;
    lastName: string;
    phone: PhoneDetailes;
    country: string | string[];
    languages: string[];
    birthday: Date;
    termsAgreed: boolean;
  };

  type emailPasswordType = {
    email: string;
    password: string;
  };

  const userDetailsUseForm = useForm<userDetailsTypes>({
    mode: "onChange",
    shouldUnregister: false,
  });
  const userDetailsForm = {
    fields: [
      {
        key: "firstName",
        label: t("first_name"),
        type: "text",
        required: true,
        validate: validateEnglish,
      },
      {
        key: "lastName",
        label: t("last_name"),
        type: "text",
        required: true,
        validate: validateEnglish,
      },
      {
        key: "phone",
        label: t("phone"),
        type: "phonenumber",
        required: true,
        validate: (phone: PhoneDetailes) => {
          try {
            const phoneNumber = parsePhoneNumber(
              phone.formatted,
              phone.countryCode as CountryCode
            );
            if (!phoneNumber.isValid()) {
              return t("invalid_phone_number");
            }
            return true;
          } catch (err) {
            return t("invalid_phone_number");
          }
        },
      },
      {
        key: "country",
        label: t("country"),
        type: "select",
        required: true,
        options: getCountries(t),
      },
      {
        key: "languages",
        label: t("languages"),
        type: "select",
        required: true,
        options: getLanguages(t, [
          "he-IL",
          "en-US",
          "ar-IL",
          "ru-RU",
          "fr-FR",
          "es-ES",
          "zh-CN",
          "cs-CZ",
          "nl-NL",
          "fi-FI",
          "id-ID",
          "it-IT",
          "ja-JP",
          "ko-KR",
          "ms-MY",
          "no-NO",
          "pl-PL",
          "pt-PT",
          "es-419",
          "sv-SE",
          "tr-TR",
          "ro-RO",
        ]),
        multiSelect: true,
      },
      {
        key: "termsAgreed",
        type: "checkbox",
        required: true,
        label: _.map(links, (link, i) => (
          <div key={`link--${i}`}>
            <span>{`${t(link.preLinkPhrase)}`}</span>
            <a
              className={"signUpLinks"}
              href={link.url}
              target="_blank"
              rel="noreferrer"
            >
              {t(link.linkName)}
            </a>
          </div>
        )),
      },
    ] as FormField[],
    useForm: userDetailsUseForm,
  };

  const emailPasswordUseForm = useForm<emailPasswordType>({
    mode: "onChange",
    shouldUnregister: false,
  });
  const emailPasswordForm = useMemo(
    () => ({
      fields: [
        {
          key: "email",
          label: t("email"),
          type: "text",
          required: true,
          validate: (value: any) =>
            !emailRegex.test(value)
              ? (t("email_is_not_valid") as string)
              : true,
          disabled: !_.isEmpty(invitationDetails),
        },
        {
          key: "password",
          label: t("password"),
          type: "password",
          required: true,
          validate: (value: any) =>
            value.length >= 10 || (t("enter_at_least", { min: 10 }) as string),
        },
        {
          key: "confirmPassword",
          label: t("password"),
          type: "password",
          required: true,
          validate: (value: any) =>
            value === emailPasswordUseForm.getValues("password") ||
            (t("password_missmatch") as string),
        },
      ] as FormField[],
      useForm: emailPasswordUseForm,
    }),
    [invitationDetails]
  );

  useEffect(() => {
    if (emailPasswordForm && invitationDetails)
      emailPasswordForm?.useForm?.setValue("email", invitationDetails.email);
  }, [invitationDetails]);

  const handleSignUpEmailPassword = async () => {
    try {
      setIsLoading(true);
      const newUserDetails = {
        ...userDetailsForm.useForm.getValues(),
        ...emailPasswordForm.useForm.getValues(),
      };
      if (invitationDetails) {
        await authService.signUp(
          {
            ...newUserDetails,
            country: newUserDetails.country[0],
            phone: "+" + String(newUserDetails.phone.phoneNumber),
          },
          invitationDetails.token
        );
      } else {
        await authService.signUp({
          ...newUserDetails,
          country: newUserDetails.country[0],
          phone: "+" + String(newUserDetails.phone.phoneNumber),
        });
      }
      setIsLoading(false);
      navigate("/mfa");
    } catch (err: any) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  const steps = [
    {
      label: t("signup_step_add_details"),
      states: ["", "logged_out"],
      form: userDetailsForm,
      step: <Form className="center" form={userDetailsForm} />,
    },
    {
      label: t("signup_step_email_password"),
      states: ["", "logged_out"],
      form: emailPasswordForm,
      step: (
        <Form
          className="center"
          form={emailPasswordForm}
          onSubmit={handleSignUpEmailPassword}
        />
      ),
      doneButtonText: t("sign_up"),
    },
  ];

  return (
    <div className="SignUpForm">
      <StepsForm
        title={t("sign_up")}
        handleDone={handleSignUpEmailPassword}
        steps={steps}
        error={error}
        loading={isLoading}
      />
    </div>
  );
};
