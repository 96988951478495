import config from "./../../config";
import { useCallback, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useStopwatch } from "react-timer-hook";
import { useReactMediaRecorder } from "react-media-recorder";
import classNames from "classnames";
import { Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faExclamation,
  faGear,
  faMicrophone,
  faX,
} from "@fortawesome/pro-light-svg-icons";

import { faPause } from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  Input,
  MultiSelect,
  SpinningLoader,
} from "@sumit-platforms/ui-bazar";
import { secondsToTimeString } from "@sumit-platforms/ui-bazar/utils";
import { clientStore } from "../../store/client";
import { useGlobalData } from "../../store";
import {
  Client,
  languages,
  Languages,
  MediaProvider,
  Upload,
} from "@sumit-platforms/types";
import {
  useAuth,
  useProjects,
  useToast,
  useUploads,
} from "@sumit-platforms/ui-bazar/hooks";

import { ProjectService } from "@sumit-platforms/ui-bazar/services";
import jobService from "../../services/jobService";

import "./RecordPage.scss";

const projectService = ProjectService({
  config,
});

export const RecordPage = () => {
  const { setToast } = useGlobalData();
  const { client } = clientStore();
  const { user } = useAuth({ config });

  const { t } = useTranslation();
  const timer = useStopwatch({});
  const {
    status,
    startRecording,
    pauseRecording,
    stopRecording,
    mediaBlobUrl,
    error,
  } = useReactMediaRecorder({
    video: false,
    askPermissionOnMount: true,
    blobPropertyBag: { type: "audio/wav" },
    onStop: (blobUrl) => handleSubmit(blobUrl),
  });
  const recordingDuration = useRef(0);

  const { toastSuccess, toastError } = useToast({ setToast });
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);
  const [project, setProject] = useState<number>();
  const [language, setLanguage] = useState({
    input: ["he-IL"] as Languages[],
    output: [],
  });

  const { handleCreateUpload, updateUploads } = useUploads({
    config,
    user,
    idClient: client?.idClient,
  });
  const { projects } = useProjects({ config, idClient: client?.idClient });

  const projectOptions = useMemo(
    () => projects.map((p) => ({ value: p.idProject, label: p.name })),
    [projects]
  );
  const isRecording = useMemo(() => status === "recording", [status]);

  const toggleOpenDetailsPanel = useCallback(() => {
    setIsDetailsPanelOpen((prev) => !prev);
  }, []);

  const handleLanguageChange = (
    io: "input" | "output",
    languages: string[]
  ) => {
    setLanguage((prev) => ({
      ...prev,
      [io]: languages,
    }));
  };

  const handleProjectChange = (idProject: number) => {
    setProject(idProject);
  };

  const handleProjectCreate = useCallback(
    async (projectName: string) => {
      if (!client) return;
      try {
        const project = await projectService.createNewProject({
          idClient: client.idClient,
          name: projectName,
        });
        // setProjects((prev) => [{ ...project, settings: {} }, ...prev]);
        toastSuccess(t("project_creation_succeed"));
      } catch (err) {
        toastError(t("project_creation_failed"));
      }
    },
    [client?.idClient, projectService]
  );

  const handleRecordToggle = useCallback(() => {
    recordingDuration.current = timer.totalSeconds;
    if (isRecording) {
      handlePauseRecord();
    } else {
      handleStartRecord();
    }
  }, [timer, timer.totalSeconds]);

  const handleStartRecord = () => {
    startRecording();
    timer.start();
  };

  const handlePauseRecord = () => {
    pauseRecording();
    timer.pause();
  };

  const handleStopRecord = () => {
    stopRecording();
    timer.pause();
  };

  const handleResetRecord = () => {
    stopRecording();
    timer.reset(new Date(), false);
  };

  const handleSubmit = useCallback(
    async (blobUrl: string) => {
      try {
        setIsLoading(true);
        if (!blobUrl) return;

        const fileResponse = await fetch(blobUrl);
        const blob = await fileResponse.blob();

        const file = new File(
          [blob],
          `Record ${format(new Date(), "dd-MM-yyyy")}.wav`,
          {
            type: blob.type,
            lastModified: new Date().getTime(),
          }
        );
        await handleCreateUpload({
          files: [new File([file], file.name)],
          onFail: () => toastError(t("upload_failed")),
          client: client as Client,
          mediaProvider: MediaProvider.mustRecorder,
          duration: recordingDuration.current,
          onSuccess: async (upload: Upload) => {
            await updateUploads([upload.idUpload], {
              inputLanguage: language.input,
              outputLanguage: language.output,
              idProject: project,
              jobType: {
                typeName: "interview",
                autoTranscript: true,
              },
            });

            await jobService.create([upload.idUpload]);

            toastSuccess(t("submit_record_success"));
            handleResetRecord();
            setIsLoading(false);
          },
        });
      } catch (error) {
        toastError(t("submit_record_fail"));
        setIsLoading(false);
      }
    },
    [timer.totalSeconds]
  );

  return (
    <Grid container className="RecorderPage Page">
      <Grid container className="floatingHeader">
        <Grid item>
          {isDetailsPanelOpen ? (
            <FontAwesomeIcon
              className="openDetailsPanelButton"
              icon={faX}
              size="xl"
              onClick={toggleOpenDetailsPanel}
            />
          ) : (
            <FontAwesomeIcon
              className="openDetailsPanelButton"
              icon={faGear}
              size="2x"
              onClick={toggleOpenDetailsPanel}
            />
          )}
        </Grid>
      </Grid>
      <Grid item className="recorderContainer" alignContent="center">
        <Grid
          container
          direction="column"
          alignItems="center"
          p={6}
          gap={2}
          alignContent={"center"}
        >
          <Grid item>
            <Typography
              variant="h4"
              className={isRecording ? "recording-text" : ""}
            >
              {isRecording ? t("recording") : t("press_to_record")}
            </Typography>
          </Grid>

          <Grid item className="recordButtonContainer">
            {isLoading ? (
              <SpinningLoader style={{ width: 100 }} />
            ) : (
              <Button
                className={classNames("recordButton round", {
                  recording: isRecording,
                })}
                onClick={handleRecordToggle}
                disabled={isLoading}
                loading={isLoading}
                sx={{ padding: 8 }}
              >
                <FontAwesomeIcon icon={isRecording ? faPause : faMicrophone} />
              </Button>
            )}
          </Grid>

          <Grid item>
            <Typography variant="h4">
              {secondsToTimeString(timer.totalSeconds)}
            </Typography>
          </Grid>

          <Grid item>
            <Button
              onClick={handleStopRecord}
              loading={isLoading}
              disabled={isRecording || timer.seconds < 3}
            >
              Submit
            </Button>
          </Grid>

          {error && (
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <FontAwesomeIcon icon={faExclamation} color="error" />
              </Grid>
              <Grid item>
                <Typography color="error">{error}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isDetailsPanelOpen && (
        <Grid item className="recordDetailsContainer">
          <Grid container direction="column" p={6} gap={2}>
            <Grid item className="action">
              <div className="inputTitle">{`${t(
                "select_source_language"
              )}:`}</div>
              <MultiSelect
                selected={[language.input[0]]}
                setSelected={(value: string[]) =>
                  handleLanguageChange("input", value)
                }
                placeholder="Not selected"
                isMulti={false}
                options={languages}
              />
            </Grid>
            <Grid item className="action">
              <div className="inputTitle">{`${t(
                "select_target_language"
              )}:`}</div>
              <MultiSelect
                selected={language.output}
                setSelected={(value: string[]) =>
                  handleLanguageChange("output", value)
                }
                placeholder="Not selected"
                isMulti={true}
                options={languages}
              />
            </Grid>
            <Grid item className="action">
              <div className="inputTitle">{`${t("select_project")}:`}</div>
              <MultiSelect
                setSelected={(value) => handleProjectChange(value[0])}
                selected={[project]}
                options={projectOptions}
                addNewOption={{ onApprove: handleProjectCreate }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default RecordPage;
