import { useMemo, useState } from "react";
import classNames from "classnames";
import { SpinningLoader } from "@sumit-platforms/ui-bazar";

import { RecallEvent } from "./MeetingSchedule";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMicrophone } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faHourglass } from "@fortawesome/pro-light-svg-icons";

const MeetingScheduleEvent = ({
  event,
  toggleEventRecord,
  isLoading,
}: {
  event: RecallEvent;
  toggleEventRecord: (event: RecallEvent) => void;
  isLoading?: boolean;
}) => {
  const navigate = useNavigate();

  const goToJob = () => {
    if (!event.idJob || event.status !== "DONE") return;
    navigate(`/job/${event.idJob}`);
  };

  const actions = useMemo(() => {
    if (event.status === "DONE") {
      return (
        <div className="goToJob">
          <FontAwesomeIcon width={10} color={"white"} icon={faCheck} />
        </div>
      );
    }

    if (event.status === "RECORDING") {
      return <div className="rec">REC</div>;
    }

    if (event.status === "PROCESSING") {
      return <FontAwesomeIcon width={10} color={"white"} icon={faHourglass} />;
    }

    if (event.end < new Date()) {
      return;
    }

    return (
      <div className="toggleRecord" onClick={() => toggleEventRecord(event)}>
        {event.hasBot ? (
          <FontAwesomeIcon width={10} color={"white"} icon={faCalendarCheck} />
        ) : (
          <FontAwesomeIcon width={10} color={"red"} icon={faMicrophone} />
        )}
      </div>
    );
  }, [event, isLoading]);

  return (
    <div className="event" onClick={goToJob}>
      {event.meetingUrl && (
        <div
          className={classNames("actions", event.status, {
            hasBot: event.hasBot,
            past: new Date() > event.end,
            isLoading,
          })}
        >
          {isLoading ? (
            <SpinningLoader style={{ width: 14, height: 14, color: "white" }} />
          ) : (
            actions
          )}
        </div>
      )}
      <div className="eventTitle">{event.title}</div>
    </div>
  );
};

export default MeetingScheduleEvent;
