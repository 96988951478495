import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { FC, useMemo } from "react";
interface IWelcomeProps {
  userName?: string;
}

const Welcome: FC<IWelcomeProps> = ({ userName }) => {
  const { t } = useTranslation();

  const greet = useMemo(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return t("greeting_morning");
    } else if (currentHour < 18) {
      return t("greeting_afternoon");
    } else {
      return t("greeting_evening");
    }
  }, []);

  return (
    <div className="WelcomeComponent">
      <Typography variant="h6">
        {greet} {userName}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Whare are we doing today?
      </Typography>
    </div>
  );
};

export default Welcome;
