import axios from "axios";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "./useFeatureFlag";

export const useErrorInterceptor = ({
  signOut,
  openReauthModal,
}: {
  signOut: () => void;
  openReauthModal?: () => void;
}) => {
  const navigate = useNavigate();
  const forceLogin401FF = useFeatureFlag("opera_401_force_login");
  const reAuth498FF = useFeatureFlag("opera_498_re_auth");

  const handle403 = useCallback(() => {
    navigate("/unauthorized");
  }, []);

  const handle401 = useCallback((error: any) => {
    const url = error.response?.config?.url;
    console.error(`user got 401 form: ${url}`);
    if (forceLogin401FF && signOut) {
      //TODO: add a prompt modal that request the user to login
      signOut();
    }
  }, []);

  const handle498 = useCallback((error: any) => {
    if (openReauthModal) {
      openReauthModal();
    }
  }, []);

  const handleError = useCallback(
    (error: any) => {
      switch (error?.response?.status) {
        case 403:
          handle403();
          break;
      }
      switch (error?.response?.status) {
        case 401:
          // handle401(error);
          break;
      }

      switch (error?.response?.status) {
        case 498:
          if (!reAuth498FF) return;
          Sentry.captureException(error);
          handle498(error);
          break;
      }
    },
    [reAuth498FF, forceLogin401FF, handle403, handle401, handle498]
  );

  useEffect(() => {
    const errorInterceptor = (error: any) => {
      handleError(error);
      Sentry.captureException(error);

      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      errorInterceptor
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);
};
