import React, { useCallback, useMemo } from "react";
import { BriefFormat } from "@sumit-platforms/types";
import { ButtonGroupOption, ToggleButtonGroup } from "../../../index";
import { useTranslation } from "react-i18next";
import { faList, faAlignLeft } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  defaultValue?: BriefFormat;
  value: BriefFormat;
  onChange: (value: BriefFormat) => void;
  isLoading?: boolean;
}

const BriefFormatGroup = ({
  value,
  defaultValue,
  onChange,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const briefButtons: ButtonGroupOption[] = useMemo(() => {
    const buttons = [
      {
        icon: faList,
        value: BriefFormat.BULLETS,
        label: t(BriefFormat.BULLETS),
      },
      {
        icon: faAlignLeft,
        value: BriefFormat.PARAGRAPHS,
        label: t(BriefFormat.PARAGRAPHS),
      },
    ];
    return buttons;
  }, []);

  const handleBriefFormatChange = useCallback(
    (event: any, value: BriefFormat) => {
      onChange(value);
    },
    []
  );

  return (
    <ToggleButtonGroup
      buttons={briefButtons}
      exclusive={true}
      onChange={handleBriefFormatChange}
      value={value}
      disabled={isLoading}
      defaultValue={defaultValue}
      label={t("brief_format")}
    />
  );
};

export default BriefFormatGroup;
